import React, { useCallback } from "react";
import { Page } from "modules/common/components/page/Page";
import { DetailedOrderListType } from "../models/detailed-order";
import { SessionType } from "modules/session/auth/models/session";
import { routes } from "modules/common/routes";
import { RouteChildrenProps } from "react-router";
import { Tabs, Tab } from "@blueprintjs/core";
import styles from "./MainTabsPage.module.scss";
import { MainTable } from "./table/Table";
// import { Dashboard } from "./dashboard/Dashboard";
// import { can } from "modules/session/auth/access";
// import func from "modules/orders-manage/functionalities";
import { MoneyAccountStoreType } from "../models/money-accounts";
import { DashboardStoreType } from "../models/dashboard-store";
import { tabs } from "./tabs";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";

export const MainTabsPage = (props: MainTabsPageProps & RouteChildrenProps) => {
  const { session, table, location, history, shortOrders } = props;
  // const { dashboard } = props;

  const tabId = location.hash.includes(tabs.charts) ? tabs.charts : tabs.table;

  const handleTabChange = useCallback(
    (newTabId: string) => {
      const route = routes.orders.manage + newTabId;
      history.push(route);
    },
    [history]
  );

  // const chartVisible = can(func.FLOW_ORDERS_CHART, access);

  return (
    <Page>
      <Tabs
        className={`planr-tabs-navigation ${styles.tabs} `}
        id="MainTabsPage"
        onChange={handleTabChange}
        selectedTabId={tabId}
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id={tabs.table}
          title={"Управление договорами"}
          panel={<MainTable store={table} session={session} shortOrders={shortOrders} />}
          className="refresh-tabbtn"
        />
      </Tabs>
    </Page>
  );
};

interface MainTabsPageProps {
  access: string[];
  table: DetailedOrderListType;
  shortOrders: OrderDictionaryType;
  dashboard: DashboardStoreType;
  accounts: MoneyAccountStoreType;
  session: SessionType;
}
