import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { DictionaryItem } from "modules/common/models/entity";
import { OrderSummarySnapshotType } from "./order";
import { Queryable } from "modules/common/models/queryable";
import { DictionaryLink } from "modules/common/models/dictionary-link";

export const OrderDictionaryItem = types
  .compose(
    DictionaryItem,
    types.model({
      inventoryNumber: types.number,
      fullInventoryNumber: types.string,
      orderNumber: types.string,
      name: types.string,
      color: types.string,
      status: types.string,
      statusColor: types.string,
      employer: types.maybeNull(DictionaryLink),
    })
  )
  .named("OrderDictionaryItem");

export const OrderDictionary = types
  .compose(
    Transport,
    Notificator,
    Queryable,
    types.model({
      orders: types.array(OrderDictionaryItem),
    })
  )
  .actions((self) => ({
    load: flow(function* () {
      try {
        const data: any[] = yield self.transport.get<any>(apiUrls.orders.dictionary());
        applySnapshot(self.orders, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    addOrder(order: OrderSummarySnapshotType, statusColor: string) {
      self.orders.unshift({
        color: order.color,
        id: order.id,
        fullInventoryNumber: order.fullInventoryNumber,
        inventoryNumber: order.inventoryNumber,
        label: order.name,
        name: order.name,
        orderNumber: order.orderNumber,
        status: order.orderStatusLink?.name ?? "",
        statusColor,
      });
    },
  }))
  .views((self) => ({
    get isEmpty() {
      return !self.orders.length;
    },

    get asMap() {
      return self.orders.reduce((result, department) => {
        result[department.id] = department;
        return result;
      }, {} as TStringMap<OrderDictionaryItemType>);
    },
    get data() {
      const predicate = (o: OrderDictionaryItemType) => {
        let result = true;
        if (self.pureQuery) {
          result = result && `${o.name} ${o.fullInventoryNumber} `.toLowerCase().indexOf(self.pureQuery) >= 0;
        }
        return result;
      };
      return self.orders.filter(predicate).map((order) => ({
        ...getSnapshot(order),
      }));
      // const order = self.sorter.asc ? "asc" : "desc";

      // return orderBy(getSnapshot(self.ipdTypes), [(o) => getIn(o, self.sorter.column)], [order]);
    },
  }))
  .named("OrderDictionary");

export type OrderDictionaryItemType = typeof OrderDictionaryItem.Type;
export type OrderDictionaryItemSnapshotType = typeof OrderDictionaryItem.SnapshotType;
export type OrderDictionaryType = typeof OrderDictionary.Type;
export type OrderDictionarySnapshotType = typeof OrderDictionary.SnapshotType;

export const initialState = (): OrderDictionarySnapshotType => ({
  orders: [],
  pureQuery: "",
  query: "",
});
