import { Button, Classes, Icon, InputGroup, Popover, Position, Spinner, Tooltip } from "@blueprintjs/core";
import { findIndex } from "lodash";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import {
  ActualPaymentType,
  OutsourcerPaymentSnapshotType,
  OutsourcerPaymentType,
  outsourcerPaymentFields,
} from "modules/agents/outsourcers/models/outsourcer-payment";
import { FileLink } from "modules/common/components/files/FileLink";
import { FileUploaderHOC } from "modules/common/components/files/Uploader";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { Money, formatMoney } from "modules/common/components/money/Money";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon, GeneralIcons } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { DATE_FORMAT, LARGE_ICON_AS_BUTTON_SIZE } from "modules/common/constants";
import { getDragItemStyle } from "modules/common/services/drag";
import { DefaultSelectedOption, SimpleSelect, renderSingleOption } from "modules/common/services/form/select";
import { onlyDigitsInputKeyPress, preventSubmitKeyDown } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OrderSummaryType } from "modules/orders-manage/models/order";
import { EventDateType, EventTypes } from "modules/orders-manage/models/order-events";
import {
  InvoiceFileSnapshotType,
  OrderFileLabel,
  OrderFileSnapshotType,
  OrderFileSorter,
} from "modules/orders-manage/models/order-file";
import { getDebit, outsourcedSpendingFields } from "modules/orders-manage/models/order-spending";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import { Constants } from "modules/root/models/constants";
import moment from "moment";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { NumberFormatValues } from "react-number-format";
import { ActualPaymentSnapshotType } from "../../../../agents/outsourcers/models/outsourcer-payment";
import { SpendingComponents, UnknownEventType } from "../../finance/events/BusinessEvent";
import portal from "../../portal";
import { OrderBase } from "../../summary/OrderBase";
import { OrderFiles } from "../../summary/OrderFiles";
import { OutsourcedOrderSpendingSchema, otherSpendingLabels } from "../../validation";
import styles from "./OutsourcedSpendings.module.scss";
import { OutsourcerSelect } from "./OutsourcerSelect";
import { ACTUAL_PAYMENTS_BLOCK_NAME, MAIN_DATA_BLOCK_NAME, PLAN_PAYMENTS_BLOCK_NAME } from "./print-view-model";

const digits = onlyDigitsInputKeyPress();
const labels = otherSpendingLabels();
const fields = outsourcedSpendingFields;
const schema = OutsourcedOrderSpendingSchema(false);

const OutsourcerOrderLabel = OrderFileLabel("Договор");

export interface PaymentStatusToggler {
  toggleStatus?: (guid: string) => void;
  formDirty: boolean;
}

export class OutsourcedSpendingRow extends React.PureComponent<OutsourcedSpendingRowProps> {
  renderInternal() {
    const {
      spending,
      onRemove,
      onChange,
      paymentFactory,
      formDirty,
      printOrderFile,
      details,
      newIdd,
      dirty,
      allPayments,
    } = this.props;
    const { toggleStatus, readOnly, agents } = this.props;
    const { draggable, highlight, disabled } = this.props;
    const outsourcerId = spending[fields.outsourcerId];
    const content = spending[fields.contentGuid] === "" || spending[fields.contentGuid] === null;

    const agent = agents.asMap[outsourcerId];
    const canPrintPhys = printOrderFile && agent && !agent.isLegalEntity && !agent.selfEmployed;
    const canPrintSelf = printOrderFile && agent && agent.selfEmployed;
    return (
      <div
        ref={draggable.innerRef}
        {...draggable.draggableProps}
        {...draggable.dragHandleProps}
        className={styles.row}
        style={this.getItemStyle()}
      >
        <div className={styles.left}>
          <div className={styles.one}>
            <div className={styles.wrapper}>
              <div className={styles.one}>
                {/** Выдано \ сдано \ принято */}
                <div className={styles.taskState}>
                  <TaskState spending={spending} readOnly={readOnly} onChange={onChange} />

                  <div className="status-doc-del">
                    {!(disabled || readOnly) && (
                      <RemoveConfirmation
                        onConfirmed={onRemove}
                        what={() => "исполнителя (включая оплаты, договоры и другие данные)"}
                        render={({ confirmRemoving }) => {
                          return (
                            <PlanrButton
                              icon="general-trash"
                              type={"graybtn"}
                              round={true}
                              onClick={confirmRemoving}
                              title={labels[fields.taskAccepted]}
                            />
                          );
                        }}
                      />
                    )}
                  </div>
                </div>

                {/** Сумма и остаток */}
                <div className={styles.mainInfo}>
                  {MAIN_DATA_BLOCK_NAME}
                  <div>
                    <OrderSum spending={spending} readOnly={disabled || readOnly} onChange={onChange}>
                      <div style={{ minWidth: "155px" }}>
                        <label>Срок выполнения (дни):</label>
                        <InputGroup
                          value={spending[fields.days] ?? ""}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            const val = parseInt(e.currentTarget.value, 10);
                            onChange(fields.days, isNaN(val) ? null : val);
                          }}
                          className="planr-default-input"
                          autoComplete="off"
                          data-lpignore="true"
                          onKeyDown={preventSubmitKeyDown}
                          onKeyPress={digits}
                          disabled={disabled || readOnly}
                        />
                      </div>
                    </OrderSum>

                    <div className={styles.outsourcer}>
                      <label>Исполнитель:</label>
                      <OutsourcerSelect
                        agents={agents}
                        field={fields.outsourcerId}
                        label={labels[fields.outsourcerId]}
                        onChange={onChange}
                        readOnly={disabled || readOnly}
                        value={outsourcerId}
                        includeClient={content}
                      />
                    </div>
                    <div style={{ marginTop: "33px", display: "flex" }}>
                      {/* Номер и дата договора */}
                      <OrderBase
                        inline={true}
                        what="Договор"
                        schema={schema}
                        number={spending[fields.orderNumber]}
                        date={spending[fields.startDate]}
                        onChange={onChange}
                        addOrderFile={this.addOrderFile}
                        readOnly={disabled || readOnly}
                        upload={this.props.upload}
                        newId={this.props.newId}
                      >
                        {(canPrintPhys || canPrintSelf) && (
                          <PlanrButton
                            icon="general-import"
                            type={"secondary"}
                            size="small"
                            onClick={this.printOrderFile}
                            title="Составить договор"
                            style={{
                              width: "60px",
                              marginTop: "11px",
                              height: "38px",
                              marginLeft: "12px",
                            }}
                          />
                        )}
                      </OrderBase>
                    </div>
                  </div>
                  <div>
                    {/* Список файлов договоров с подрядчиком */}
                    <div className="order-files-list">
                      <OrderFiles<OrderFileSnapshotType>
                        readOnly={disabled || readOnly}
                        removeLabel={OutsourcerOrderLabel}
                        fileLabel={OutsourcerOrderLabel}
                        sorter={OrderFileSorter}
                        files={spending[fields.orders]}
                        baseUrl={this.props.baseUrl}
                        onRemove={(id) => {
                          const value = spending[fields.orders].filter((f: OrderFileSnapshotType) => f.guid !== id);
                          onChange(fields.orders, value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PlanPayments
              outsourcer={spending[fields.outsourcerId]}
              value={spending[fields.planPayments]}
              factory={paymentFactory}
              onChange={(paymnets) => {
                onChange(fields.planPayments, paymnets);
              }}
              readOnly={readOnly}
              formDirty={formDirty}
              toggleStatus={toggleStatus}
              baseUrl={this.props.baseUrl}
              upload={this.props.upload}
              newIdd={newIdd}
              details={details}
              spendingId={spending[fields.id]}
              dirty={dirty}
              allPayments={allPayments}
              highlighted={highlight || ""}
            />
          </div>
        </div>

        <ActualPayments data={spending[fields.actualPayments]} highlighted={highlight || ""} />
      </div>
    );
  }

  printOrderFile = () => {
    const { spending, printOrderFile } = this.props;
    const outsourcerId = spending[fields.outsourcerId];
    let contentGuid = spending[fields.contentGuid] ?? "";
    let comment = spending[fields.comment] ?? "";

    printOrderFile && printOrderFile(outsourcerId, contentGuid, comment);
  };

  render() {
    const { isDragging } = this.props;
    const result = this.renderInternal();

    return isDragging ? ReactDOM.createPortal(result, portal) : result;
  }

  getItemStyle = (): React.CSSProperties => {
    const { isDragging, draggable } = this.props;
    return getDragItemStyle(isDragging, draggable.draggableProps.style);
  };

  addOrderFile = (file: OrderFileSnapshotType) => {
    const { spending, onChange } = this.props;
    const value = spending[fields.orders];
    file.sortOrder = value.length + 1;

    onChange(fields.orders, [file, ...value]);
  };
}

interface OutsourcedSpendingRowProps extends PaymentStatusToggler, IdFactory, UploaderFatory {
  dirty: boolean;
  disabled: boolean | undefined;
  isDragging: boolean;
  draggable: DraggableProvided;
  spending: TStringMap<any>;
  agents: OutsourcerDictionaryType;
  onChange: (field: string, value: any) => void;
  printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
  onRemove: () => void;
  paymentFactory: OutsourcerPaymentFactory;
  readOnly?: boolean;
  highlight?: string;
  baseUrl: string;
  details: OrderSummaryType;
  newIdd: () => Promise<string>;
  allPayments: ActualPaymentType[];
}

interface ActualPaymentsProps {
  data: ActualPaymentSnapshotType[];
  highlighted: string;
}

class ActualPayments extends React.PureComponent<ActualPaymentsProps> {
  render() {
    const { data, highlighted } = this.props;

    return (
      <div className={styles.actuals}>
        <div className={styles.paymentsName}>{ACTUAL_PAYMENTS_BLOCK_NAME}</div>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.headerRow}>
              <td className={styles.dateTop}>Дата:</td>
              <td className={styles.sumTop}>Сумма:</td>
              <td className={styles.sumTop}>Корректировка:</td>
            </tr>
            {data.map((payment) => {
              const correctionSum = payment.correctionPayments.reduce((accumulator, object) => {
                return accumulator + object.sum;
              }, 0);

              return (
                <tr
                  key={payment.guid}
                  // data-object-id={payment.planPaymentGuid}
                  className={highlighted === payment.guid ? "payment-row-highlighted" : ""}
                >
                  <td>{payment.day}</td>
                  <td>
                    <Money amount={payment.sum} />
                  </td>
                  <td>
                    {payment.correctionPayments.length > 0 && (
                      <Tooltip
                        className={Classes.TOOLTIP_INDICATOR}
                        content={
                          <div className={styles.actuals}>
                            <table className={styles.table}>
                              <tbody>
                                <tr className={styles.headerRow}>
                                  <td className={styles.dateTop}>Дата:</td>
                                  <td className={styles.sumTop}>Сумма:</td>
                                </tr>
                                {payment.correctionPayments.map((p) => (
                                  <tr
                                    key={p.guid}
                                    // data-object-id={p.guid}
                                    className={highlighted === p.guid ? "payment-row-highlighted" : ""}
                                  >
                                    <td>{p.day}</td>
                                    <td>
                                      <Money amount={p.sum} />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        }
                      >
                        <Money amount={payment.sum - correctionSum} />
                      </Tooltip>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

interface TaskStateProps {
  spending: TStringMap<any>;
  onChange: (field: string, value: any) => void;
  readOnly?: boolean;
}

export class TaskState extends React.PureComponent<TaskStateProps> {
  render() {
    const { spending } = this.props;

    const issued = !!spending[fields.taskIssued];
    const completed = !!spending[fields.taskCompleted];
    const accepted = !!spending[fields.taskAccepted];

    return (
      <div>
        <span>Статус</span>
        <div className="status-doc">
          <Popover
            position="right"
            isOpen={undefined}
            interactionKind="hover"
            content={
              issued ? (
                <div style={{ padding: "7px" }}>
                  <div className={`${Classes.INPUT_GROUP} `}>
                    <DatePicker
                      boundary="viewport"
                      value={moment(spending[fields.taskIssuedDate], DATE_FORMAT).toDate()}
                      onChange={this.issuedDateChange}
                      clasName="planr-default-input"
                    />
                  </div>
                </div>
              ) : undefined
            }
          >
            <PlanrButton
              icon="general-doc-arrow-right"
              type={issued ? "blueish" : "graybtn"}
              round={true}
              onClick={() => {
                this.issuedChange();
                //  this.issuedDateChange();
              }}
              title={labels[fields.taskIssued]}
            />
          </Popover>
        </div>
        <div className="status-doc">
          <Popover
            position="right"
            isOpen={undefined}
            interactionKind="hover"
            content={
              completed ? (
                <div style={{ padding: "7px" }}>
                  <div className={`${Classes.INPUT_GROUP} `}>
                    <DatePicker
                      boundary="viewport"
                      value={moment(spending[fields.taskCompletedDate], DATE_FORMAT).toDate()}
                      onChange={this.completedDateChange}
                      clasName="planr-default-input"
                    />
                  </div>
                </div>
              ) : undefined
            }
          >
            <PlanrButton
              icon="general-doc-check"
              type={completed ? "blueish" : "graybtn"}
              round={true}
              onClick={() => {
                this.completedChange();
                //
              }}
              title={labels[fields.taskCompleted]}
            />
          </Popover>{" "}
        </div>
        <div className="status-doc">
          <Popover
            position="right"
            isOpen={undefined}
            interactionKind="hover"
            content={
              accepted ? (
                <div style={{ padding: "7px" }}>
                  <div className={`${Classes.INPUT_GROUP} `}>
                    <DatePicker
                      boundary="viewport"
                      value={moment(spending[fields.taskAcceptedDate], DATE_FORMAT).toDate()}
                      onChange={this.acceptedDateChange}
                      clasName="planr-default-input"
                    />
                  </div>
                </div>
              ) : undefined
            }
          >
            <PlanrButton
              icon="general-check"
              type={accepted ? "greenish" : "graybtn"}
              round={true}
              onClick={() => {
                this.acceptedChange();
              }}
              title={labels[fields.taskAccepted]}
            />
          </Popover>
        </div>
      </div>
    );
  }

  onChange = (field: string) => () => {
    const { spending, onChange, readOnly } = this.props;

    const value = !!spending[field];
    !readOnly && onChange(field, !value);
  };

  issuedChange = this.onChange(fields.taskIssued);
  issuedDateChange = (date: Date | null) => {
    if (date) {
      this.props.onChange(fields.taskIssuedDate, moment(date).local().format(DATE_FORMAT));
    } else {
      this.props.onChange(fields.taskIssuedDate, null);
    }
  };

  completedDateChange = (date: Date | null) => {
    if (date) {
      this.props.onChange(fields.taskCompletedDate, moment(date).local().format(DATE_FORMAT));
    } else {
      this.props.onChange(fields.taskCompletedDate, null);
    }
  };

  acceptedDateChange = (date: Date | null) => {
    if (date) {
      this.props.onChange(fields.taskAcceptedDate, moment(date).local().format(DATE_FORMAT));
    } else {
      this.props.onChange(fields.taskAcceptedDate, null);
    }
  };

  completedChange = this.onChange(fields.taskCompleted);

  acceptedChange = this.onChange(fields.taskAccepted);
}

interface OrderSumProps {
  spending: TStringMap<any>;
  onChange: (field: string, value: any) => void;
  readOnly?: boolean;
}

export class OrderSum extends React.PureComponent<OrderSumProps> {
  render() {
    const { spending, readOnly, children } = this.props;

    return (
      <div className={styles.orderSum}>
        <div>
          <label className={styles.labelSum}>{labels[fields.actualSum]}:</label>
          <MoneyInput
            className="planr-default-input"
            value={spending[fields.actualSum]}
            onMoneyChange={this.onChange}
            autoComplete="off"
            data-lpignore="true"
            onKeyDown={preventSubmitKeyDown}
            disabled={readOnly}
          />
        </div>
        <div>
          <label className={styles.labelSum}>Остаток:</label>
          <MoneyInput
            className="planr-default-input"
            value={`${getDebit(spending as any, "actual")}`}
            disabled={true}
            onMoneyChange={eat}
          />
        </div>
        {children}
      </div>
    );
  }

  onChange = (money: NumberFormatValues) => {
    if (!this.props.readOnly) {
      this.props.onChange(fields.actualSum, money.value);
    }
  };
}

interface PlanPaymentsProps extends PaymentStatusToggler, UploaderFatory {
  value: OutsourcerPaymentType[];
  factory: OutsourcerPaymentFactory;
  title?: string;
  onChange: (value: OutsourcerPaymentSnapshotType[]) => void;
  readOnly?: boolean;
  baseUrl: string;
  outsourcer: string;
  details: OrderSummaryType;
  newIdd: () => Promise<string>;
  dirty: boolean;
  allPayments: ActualPaymentType[];
  spendingId: string;
  highlighted: string;
}

export class PlanPayments extends React.Component<PlanPaymentsProps> {
  render() {
    const {
      value,
      title,
      readOnly,
      formDirty,
      toggleStatus,
      upload,
      baseUrl,
      outsourcer,
      details,
      newIdd,
      dirty,
      allPayments,
      spendingId,
      highlighted,
    } = this.props;
    return (
      <div className={styles.plan}>
        <div className={styles.paymentsName}>{title || PLAN_PAYMENTS_BLOCK_NAME}</div>
        <div className={styles.table}>
          {value.map((payment) => (
            <PlanPaymentRow
              key={payment.guid}
              onChange={(field1, text1, field2, text2, field3, text3, field4, text4) => {
                this.onPaymentChange(payment, field1, text1, field2, text2, field3, text3, field4, text4);
              }}
              onChangeInvoice={(field, text) => {
                this.onPaymentInvoiceChange(payment, field, text);
              }}
              newIdd={newIdd}
              onRemove={this.removePayment}
              payment={payment}
              readOnly={readOnly}
              toggleStatus={toggleStatus}
              formDirty={formDirty}
              upload={upload}
              baseUrl={baseUrl}
              outsourcer={outsourcer}
              details={details}
              dirty={dirty}
              allPayments={allPayments}
              spendingId={spendingId}
              highlighted={highlighted}
            />
          ))}
        </div>
        {!readOnly && (
          <PlanrButton
            icon="general-plus-big"
            type="dashed"
            onClick={this.addPayment}
            style={{ width: "100%", marginTop: "16px" }}
          >
            Добавить оплату
          </PlanrButton>
        )}
      </div>
    );
  }

  onPaymentChange = (
    payment: OutsourcerPaymentType,
    field1: string,
    text1: any,
    field2: string,
    text2: any,
    field3: string,
    text3: any,
    field4: string,
    text4: any
  ) => {
    const { value, onChange } = this.props;
    const index = value.indexOf(payment);
    const newValue = [
      ...value.slice(0, index),
      { ...payment, [field1]: text1, [field2]: text2, [field3]: text3, [field4]: text4 },
      ...value.slice(index + 1),
    ];
    onChange(newValue);
  };

  onPaymentInvoiceChange = (payment: OutsourcerPaymentType, field1: string, text1: any) => {
    const { value, onChange } = this.props;
    const index = value.indexOf(payment);
    const newValue = [...value.slice(0, index), { ...payment, [field1]: text1 }, ...value.slice(index + 1)];
    onChange(newValue);
  };

  addPayment = async () => {
    const { onChange, value } = this.props;
    const payment = await this.props.factory.emptyOutsourcerPayment(value.length + 1);
    const newValue = [...value, payment];

    onChange(newValue);
  };

  removePayment = (guid: string) => {
    const { onChange, value } = this.props;
    const index = findIndex(value, (o) => o.guid === guid);

    if (index >= 0) {
      const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
      onChange(newValue);
    }
  };
}

export interface OutsourcerPaymentFactory {
  emptyOutsourcerPayment: (index: number) => Promise<ActualPaymentSnapshotType>;
}

interface PlanPaymentRowProps extends PaymentStatusToggler, UploaderFatory {
  dirty: boolean;
  highlighted: string;
  payment: TStringMap<any>;
  readOnly?: boolean;
  onChange: (
    field1: string,
    value1: any,
    field2: string,
    value2: any,
    field3: string,
    value3: any,
    field4: string,
    value4: any
  ) => void;
  onChangeInvoice: (field: string, value: any) => void;
  newIdd: () => Promise<string>;
  onRemove: (guid: string) => void;
  baseUrl: string;
  outsourcer: string;
  details: OrderSummaryType;
  allPayments: ActualPaymentType[];
  spendingId: string;
}

interface PlanPaymentRowState {
  loading: boolean;
  eventDates: EventDateType[];
  mode: "date" | "event";
  active: boolean;
  error: string;
  money: string;
  date: Date | null;
}

type FileType = {
  fileId: string;
  fileName: string;
};
export class PlanPaymentRow extends React.PureComponent<PlanPaymentRowProps, PlanPaymentRowState> {
  constructor(props: PlanPaymentRowProps) {
    super(props);

    this.state = {
      loading: false,
      eventDates: [],
      mode: "date",
      active: false,
      money: "",
      date: null,
      error: "",
    };
  }

  componentDidMount() {
    if (this.props.payment[outsourcerPaymentFields.eventDates].length > 0) {
      this.setState({ mode: "event" });
    } else {
      this.setState({ mode: "date" });
    }
  }
  render() {
    const { readOnly, payment, outsourcer, baseUrl, details, newIdd, dirty, allPayments, highlighted } = this.props;
    let file: FileType | null = null;

    if (payment[outsourcerPaymentFields.invoice]) {
      file = {
        fileId: payment[outsourcerPaymentFields.invoice].fileId,
        fileName: payment[outsourcerPaymentFields.invoice].fileName,
      };
    }
    const date = payment[outsourcerPaymentFields.hasDate] ? payment[outsourcerPaymentFields.hasDate] : "";
    const knownDate = date && date !== payment[outsourcerPaymentFields.dateDescription] ? date : null;

    const sum = formatMoney(payment[outsourcerPaymentFields.sum]);
    const description =
      this.state.eventDates.length > 0 && dirty
        ? this.state.eventDates.map((e) => e.model.type)
        : this.state.date !== null && dirty
        ? moment(this.state.date).format(DATE_FORMAT)
        : payment[outsourcerPaymentFields.dateDescription];
    return (
      <div
        className={`${styles.planRow} ${
          highlighted && highlighted === payment[outsourcerPaymentFields.guid] && styles.highlighted
        }`}
        data-object-id={payment[outsourcerPaymentFields.guid]}
      >
        <div className={styles.btn}>
          {this.state.active && !readOnly && (
            <div>
              <GeneralIcon
                style={{ cursor: "pointer", color: "#1dd278" }}
                type="general-redo"
                onClick={() => {
                  this.savePayment();
                }}
              />
            </div>
          )}
          {!this.state.active && !readOnly && (
            <div>
              <GeneralIcon
                type="general-edit"
                style={{ cursor: "pointer", color: "#36acd7", marginLeft: "5px" }}
                onClick={() => {
                  this.setState({
                    active: true,
                    money: payment[outsourcerPaymentFields.sum],
                    date: payment[outsourcerPaymentFields.concreteDate]
                      ? moment(payment[outsourcerPaymentFields.concreteDate], "DD.MM.YYYY").toDate()
                      : null,
                    eventDates: payment[outsourcerPaymentFields.eventDates],
                  });
                }}
              />
            </div>
          )}
        </div>
        {!this.state.active && (
          <div className={styles.paymentInfo}>
            <div style={{ marginBottom: "5px" }}>Платеж №{payment[outsourcerPaymentFields.name]}</div>
            <PlanrTag
              // type={"neutral"}
              type={
                payment[outsourcerPaymentFields.status] === Constants.orderPaymentStatusPaid ? "success" : "neutral"
              }
              style={{ marginBottom: "5px" }}
            >
              {sum}
            </PlanrTag>
            <div style={{ marginBottom: "5px", textAlign: "center" }}>
              {knownDate && (
                <Tooltip content={knownDate} position={Position.TOP}>
                  <span className={Classes.TOOLTIP_INDICATOR}>{description}</span>
                </Tooltip>
              )}
              {!knownDate && description}
              {!description && <PlanrTag type={"danger"}>Укажите дату или событие</PlanrTag>}
            </div>
          </div>
        )}
        {this.state.active && (
          <div className={styles.paymentBlock}>
            <div style={{ color: "red", fontSize: "12px" }}>{this.state.error}</div>
            <div className={styles.flexRow}>
              <div className={styles.sumTop}>Сумма:</div>
              <MoneyInput
                className="planr-default-input"
                value={this.state.money}
                onMoneyChange={this.onSumChanged}
                autoComplete="off"
                data-lpignore="true"
                onKeyDown={preventSubmitKeyDown}
              />
              {/* {readOnly && (
            <div className="centered">
              <Money amount={payment[outsourcerPaymentFields.sum]} noFraction={true} />
            </div>
          )} */}
            </div>
            <div className={styles.flexRow}>
              <PlanrButton
                type={this.state.mode === "event" ? "lightblueish" : "blueish"}
                size="small"
                onClick={() => {
                  this.setState({ mode: "date" });
                }}
                style={{ height: "25px", width: "50%" }}
              >
                Дата
              </PlanrButton>
              <PlanrButton
                type={this.state.mode === "date" ? "lightblueish" : "blueish"}
                style={{ marginLeft: "0px", height: "25px", width: "50%" }}
                size="small"
                onClick={() => {
                  this.setState({ mode: "event" });
                }}
              >
                Событие
              </PlanrButton>
            </div>
            <div className={styles.flexRow}>
              <div className={styles.dateEvent}>
                {this.state.mode === "date" && (
                  <div className={`${Classes.INPUT_GROUP} `}>
                    <DatePicker
                      boundary="viewport"
                      value={this.state.date}
                      onChange={this.onDateChanged}
                      clasName="planr-default-input"
                    />
                  </div>
                )}
                {this.state.mode === "event" && (
                  <div>
                    {this.state.eventDates.map((event, index) => {
                      const eventType = Constants.businessEventTypesOptions.find((o) => o.id === event.model.type);
                      const readonly = !!event.eventId;
                      const daysCountValue = event.daysCount === 0 ? "" : event.daysCount.toString();
                      const Component = SpendingComponents.find((c) => c.TYPE === event.model.type) ?? UnknownEventType;
                      return (
                        <div className={styles.event} key={index}>
                          <div>
                            <RemoveConfirmation<EventDateType>
                              what={() => "Событие"}
                              actionName="отвязать"
                              render={({ confirmRemoving }) => (
                                <PlanrButton
                                  type="secondary"
                                  icon="general-trash"
                                  onClick={() => confirmRemoving(event)}
                                  size="small"
                                  title={texts.remove}
                                  style={{ marginBottom: "5px", width: "100%" }}
                                  className={styles.deleteButton}
                                >
                                  Отвязать событие
                                </PlanrButton>
                              )}
                              onConfirmed={() => {
                                const newEvents = this.state.eventDates.filter(
                                  (ev) => ev.model.newId !== event.model.newId
                                );
                                this.setState({ eventDates: newEvents });
                              }}
                            />
                          </div>

                          <div className={`${Classes.INPUT_GROUP}`} style={{ marginBottom: "5px" }}>
                            <SimpleSelect
                              className={`full-width-select ${Classes.FILL}`}
                              filterable={false}
                              activeItem={eventType}
                              items={Constants.businessEventTypesOptions}
                              itemRenderer={renderSingleOption}
                              onItemSelect={(i: SelectItem) => {
                                let newEvents = [...this.state.eventDates];
                                let index = newEvents.findIndex((ev) => ev.model.newId === event.model.newId);
                                if (index > -1) {
                                  let model = { ...newEvents[index].model };
                                  model.type = i.id;
                                  newEvents[index] = { ...newEvents[index], model };
                                  this.setState({ eventDates: newEvents });
                                }
                              }}
                              disabled={readonly}
                              popoverProps={{
                                popoverClassName: "business-event__break-dropdown",
                                usePortal: true,
                              }}
                            >
                              {readonly && <Button fill={true} className="selected-option" text={eventType?.label} />}
                              {!readonly && (
                                <DefaultSelectedOption
                                  option={eventType}
                                  empty={!Constants.businessEventTypesOptions.length}
                                />
                              )}
                            </SimpleSelect>
                          </div>

                          <Component
                            event={event}
                            payments={details.planPayments}
                            stages={details.stageItems}
                            spendings={allPayments.filter((p) => p.guid !== payment[outsourcerPaymentFields.guid])}
                            readonly={readonly}
                            additions={details.additions}
                            isNotStateChange={(field: string, val: any) => {
                              this.onChangeEvent(event, field, val);
                            }}
                          />

                          <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                            <div className={Classes.LABEL}>Через:&nbsp;</div>
                            <InputGroup
                              id="offcet"
                              value={daysCountValue}
                              type="text"
                              className="planr-default-input"
                              autoComplete="off"
                              data-lpignore="true"
                              onKeyPress={digits}
                              style={{ width: "135px" }}
                              onChange={(e: any) => {
                                let newEvents = [...this.state.eventDates];
                                let index = newEvents.findIndex((ev) => ev.model.newId === event.model.newId);
                                if (index > -1) {
                                  newEvents[index] = { ...newEvents[index], daysCount: e.currentTarget.value };
                                  this.setState({ eventDates: newEvents });
                                }
                              }}
                            />
                            &nbsp;
                            <GeneralIcon
                              type={event.workDays ? "general-checkbox" : "general-zero-checkbox"}
                              onClick={(e: any) => {
                                let newEvents = [...this.state.eventDates];
                                let index = newEvents.findIndex((ev) => ev.model.newId === event.model.newId);
                                if (index > -1) {
                                  newEvents[index] = { ...newEvents[index], workDays: !event.workDays };
                                  this.setState({ eventDates: newEvents });
                                }
                              }}
                              size={16}
                              style={{ cursor: "pointer" }}
                            />
                            <div className={Classes.LABEL}>&nbsp;раб.дней</div>
                            {/* <Checkbox
                              large={false}
                              checked={event.workDays}
                              onChange={(e: any) => {
                                let newEvents = [...this.state.eventDates];
                                let index = newEvents.findIndex((ev) => ev.eventId === event.eventId);
                                if (index > -1) {
                                  newEvents[index] = { ...newEvents[index], workDays: e.currentTarget.checked };
                                  this.setState({ eventDates: newEvents });
                                }
                              }}
                              label="раб.дней"
                            /> */}
                          </div>
                        </div>
                      );
                    })}
                    <PlanrButton
                      type="lightgreenish"
                      onClick={async () => {
                        const newEv: any = {
                          daysCount: 0,
                          workDays: false,
                          model: {
                            id: "",
                            newId: await newIdd(),
                            paymentGuid: "",
                            stageGuid: "",
                            status: "",
                            type: Constants.businessEventTypes[0],
                            belonging: [],
                            additionalText: "",
                            additionNumber: "",
                          },
                        };
                        let oldEvents = [...this.state.eventDates];
                        oldEvents.push(newEv);
                        this.setState({ eventDates: oldEvents });
                      }}
                      style={{ width: "100%" }}
                      size="small"
                      className={styles.addButton}
                    >
                      {texts.add} событие
                    </PlanrButton>
                  </div>
                )}
                {/* <PlanrButton type={"greenish"} icon={"general-save"} onClick={this.onDateChangeHandler} /> */}
              </div>

              {/* {readOnly && <div className="centered">{formatDate(payment[outsourcerPaymentFields.date])}</div>} */}
            </div>
          </div>
        )}

        {this.state.active && (
          <div className={styles.btn} style={{ width: "22px", marginLeft: "10px" }}>
            <GeneralIcon
              style={{ cursor: "pointer", color: "#ea561e" }}
              type="general-undo"
              onClick={() => {
                this.setState({ active: false });
              }}
            />
          </div>
        )}

        {!this.state.active && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="centered">{outsourcer && this.statusButton()}</div>
            <div className="centered">
              {!readOnly && !payment[outsourcerPaymentFields.invoice] && !this.state.loading && (
                <FileUploaderHOC
                  accept={"*"}
                  onFileSelected={this.uploadInvoiceFile}
                  render={({ onClick }) => (
                    <GeneralIcon
                      onClick={onClick}
                      type="general-attach"
                      title="Прикрепить счет"
                      style={{ cursor: "pointer", marginLeft: `${!file ? "20px" : "0px"}` }}
                    />
                  )}
                />
              )}
              {!readOnly && !payment[outsourcerPaymentFields.invoice] && this.state.loading && (
                <div className="uploader-icon">{<Spinner intent="primary" size={16} />}</div>
              )}
              {payment[outsourcerPaymentFields.invoice] && (
                <div className={styles.invoiceUploaded}>
                  <GeneralIcon
                    onClick={this.onInvoiceRemove}
                    type="general-un-attach"
                    style={{ color: "#1DD278", cursor: "pointer", marginRight: "10px" }}
                    title="Открепить счет"
                  />
                  <div style={{ marginTop: "4px", width: "20px" }}>
                    {file && (
                      <FileLink<any>
                        key={file.fileId}
                        baseUrl={baseUrl}
                        file={file}
                        readOnly={true}
                        onRemove={eat}
                        littleIcon
                        title={file.fileName}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              {!readOnly && (
                <RemoveConfirmation
                  onConfirmed={this.onRemove}
                  what={() => "оплату"}
                  render={({ confirmRemoving }) => {
                    return (
                      <GeneralIcon
                        onClick={confirmRemoving}
                        type="general-trash"
                        style={
                          file != null
                            ? { marginTop: "1px", color: "#E31818", cursor: "pointer", marginLeft: "15px" }
                            : { marginTop: "1px", color: "#E31818", cursor: "pointer", marginLeft: "25px" }
                        }
                        title={texts.remove}
                      />
                    );
                  }}
                />
              )}
              {payment[outsourcerPaymentFields.automatic] && (
                <Icon icon="lock" htmlTitle="Создано автоматически" iconSize={LARGE_ICON_AS_BUTTON_SIZE} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  savePayment = () => {
    if (this.state.money === null || this.state.money === "") {
      this.setState({ error: "Необходимо указать сумму!" });
      return;
    }
    if (+this.state.money === 0) {
      this.setState({ error: "Сумма должна быть больше 0!" });
      return;
    }

    if (this.state.date === null && this.state.eventDates.length === 0) {
      this.setState({ error: "Необходимо указать дату или событие!" });
      return;
    }
    let err = false;
    if (this.state.eventDates.length > 0) {
      this.state.eventDates.forEach((e) => {
        if (e.model.type === EventTypes.AdditionBusinessEvent && e.model.additionNumber === "") {
          err = true;
        }
        if (e.model.type === EventTypes.ChangeStatusBusinessEvent && e.model.status === "") {
          err = true;
        }
        if (
          (e.model.type === EventTypes.ConsignmentBusinessEvent ||
            e.model.type === EventTypes.ExpertiseBusinessEvent ||
            e.model.type === EventTypes.SentExpertiseBusinessEvent ||
            e.model.type === EventTypes.IpdFileBusinessEvent ||
            e.model.type === EventTypes.OrderStageCompletedBusinessEvent ||
            e.model.type === EventTypes.OrderStageStartedBusinessEvent) &&
          e.model.stageGuid === ""
        ) {
          err = true;
        }
        if (
          (e.model.type === EventTypes.OrderPaymentBusinessEvent ||
            e.model.type === EventTypes.OrderSpendingPaymentBusinessEvent) &&
          e.model.paymentGuid === ""
        ) {
          err = true;
        }
      });
    }

    if (err) {
      return;
    }
    if (this.props.payment[outsourcerPaymentFields.status] !== Constants.orderPaymentStatusPaid) {
      this.props.onChange(
        outsourcerPaymentFields.sum,
        this.state.money,
        outsourcerPaymentFields.concreteDate,
        this.state.date && this.state.eventDates.length === 0 ? moment(this.state.date).format(DATE_FORMAT) : null,
        outsourcerPaymentFields.date,
        this.state.date ? this.state.date : new Date(),
        outsourcerPaymentFields.eventDates,
        this.state.eventDates.length > 0 ? this.state.eventDates : []
      );
    }

    this.setState({ active: false });
  };

  uploadInvoiceFile = async (file: File) => {
    this.setState({ loading: true });
    const meta = await this.props.upload(file);

    if (meta) {
      const data: InvoiceFileSnapshotType = {
        ...meta,
      };

      this.props.onChangeInvoice(outsourcerPaymentFields.invoice, data);
    }
    this.setState({ loading: false });
  };

  onInvoiceRemove = () => {
    this.props.onChangeInvoice(outsourcerPaymentFields.invoice, null);
  };

  onSumChanged = (money: NumberFormatValues) => this.setState({ money: money.value });
  onChangeEvent = (event: EventDateType, field: string, value: any) => {
    let newEvents = [...this.state.eventDates];
    let index = newEvents.findIndex((ev) => ev.model.newId === event.model.newId);
    if (index > -1) {
      let model = { ...newEvents[index].model };
      switch (field) {
        case "status": {
          model.status = value;
          break;
        }
        case "statusSpending": {
          model.status = value;
          model.secondId = this.props.spendingId;
          break;
        }
        case "type": {
          model.type = value;
          break;
        }
        case "paymentGuid": {
          model.paymentGuid = value;
          break;
        }
        case "stageGuid": {
          model.stageGuid = value;
          break;
        }
        case "additionNumber": {
          model.additionNumber = value;
          break;
        }
      }
      newEvents[index] = { ...newEvents[index], model };
      this.setState({ eventDates: newEvents });
    }
  };
  onDateChanged = (date: Date | null) => this.setState({ date: date });
  // onDateChangeHandler = () => {
  //   this.props.onChange(outsourcerPaymentFields.eventDates, this.state.eventDates);
  // };
  statusIcon = (status: string): GeneralIcons => {
    switch (status) {
      case Constants.orderPaymentStatusNew:
        return "general-question";
      case Constants.orderPaymentStatusAccepted:
        return "general-like";
      case Constants.orderPaymentStatusPaid:
        return "general-check";
      default:
        return "general-credit-card";
    }
  };

  statusButton = () => {
    const { payment, formDirty, readOnly } = this.props;
    const status: string = payment[outsourcerPaymentFields.status];
    const icon = this.statusIcon(status);

    const canBeToggled = ["", Constants.orderPaymentStatusCancelled];

    if (readOnly || formDirty || !canBeToggled.includes(status)) {
      return (
        <GeneralIcon
          type={icon}
          style={
            status === Constants.orderPaymentStatusPaid
              ? { color: "#1DD278", cursor: "pointer", marginRight: "10px", marginLeft: "10px" }
              : { color: "rgba(0, 39, 61, 0.3)", cursor: "pointer", marginRight: "10px", marginLeft: "10px" }
          }
          title={status}
        />
      );
    }

    if (canBeToggled.includes(status)) {
      return (
        <GeneralIcon
          type={icon}
          onClick={this.toggleStatus}
          style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
          title={status || "Отправить запрос на оплату"}
        />
      );
    }

    return null;
  };

  toggleStatus = () => {
    const { payment, toggleStatus } = this.props;
    const id = payment[outsourcerPaymentFields.guid];

    toggleStatus && toggleStatus(id);
  };

  onRemove = () => this.props.onRemove(this.props.payment[outsourcerPaymentFields.guid]);
}
