import * as signalR from "@microsoft/signalr";
import { types, applySnapshot } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Session, initialState as sessionInitialState } from "modules/session/auth/models/session";
import { NotificationStore, initialState as alertsInitialState } from "modules/root/models/alerts";
import {
  OutsourcersStore,
  initialState as outsourcersInitialState,
} from "modules/agents/outsourcers/models/outsourcers-store";
import { ClientsStore, initialState as clientsInitialState } from "modules/agents/clients/models/clients-store";
import { SuppliersStore, initialState as suppliersInitialState } from "modules/agents/suppliers/models/suppliers-store";
import {
  WorkTypesStore,
  initialState as workTypesInitialState,
} from "modules/dictionaries/work-types/models/work-types-store";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import {
  OrderTypesStore,
  initialState as orderTypesInitialState,
} from "modules/dictionaries/order-types/models/order-types-store";
import {
  OrderStatusStore,
  initialState as orderStatusesInitialState,
} from "modules/dictionaries/order-statuses/models/order-status-store";
import {
  ProductionStagesStore,
  initialState as productionStagesInitialState,
} from "modules/dictionaries/production-stages/models/production-stages-store";

import {
  IpdTypesStore,
  initialState as ipdTypesInitialState,
} from "modules/dictionaries/ipd-types/models/ipd-types-store";

import {
  OrderIndicatorsStore,
  initialState as orderIndicatorsInitialState,
} from "modules/dictionaries/order-indicators/models/order-indcator-store";

import {
  IndicatorsStore,
  initialState as indicatorsInitialState,
} from "modules/dictionaries/order-indicators/models/indicator-store";

import {
  EmployeePositionsStore,
  initialState as employeePositionsInitialState,
} from "modules/spending/departments/models/employee-positions-store";
import { Start, initialState as startSettingsInitialState } from "modules/root/components/StartPage/models/start";

import {
  MaterialValuesStore,
  initialState as materialValuesInitialState,
} from "modules/dictionaries/inventory/models/material-value-store";

import {
  OverheadTypesStore,
  initialState as overheadTypesInitialState,
} from "modules/dictionaries/overhead-types/models/overhead-types-store";
import { EmployeeStore, initialState as employeeInitialState } from "modules/spending/employee/models/employee-store";
import {
  DepartmentsStore,
  initialState as departmentsInitialState,
} from "modules/spending/departments/models/departments-store";
import {
  CategoriesStore,
  initialState as categoriesInitialState,
} from "modules/dictionaries/inventory/models/categories-store";
import {
  DeprGroupsStore,
  initialState as deprGroupsInitialState,
} from "modules/dictionaries/inventory/models/depr-group-store";
import {
  ProjectPortfoliosStore,
  initialState as portfoliosInitialState,
} from "modules/dictionaries/project-portfolios/models/project-portfolio-store";
import {
  CompanyBankDetailsStore,
  initialState as bankDetailsInitialState,
} from "modules/dictionaries/bank-details/models/bank-details-store";
import { TokensStore, initialState as tokensInitilaState } from "modules/session/tokens/models/tokens-store";
import { AccessStore, initialState as accessInitialState } from "modules/session/access/models/access-store";
import { OrdersStore, initialState as ordersInitialState } from "modules/orders-manage/models/orders-store";
import {
  TimesheetStore,
  initialState as timesheetInitialState,
} from "modules/spending/timesheet/models/timesheet-store";
import { WorkloadStore, initialState as workloadInitialState } from "modules/spending/workload/models/workload-store";
import { ScheduleStore, initialState as scheduleInitialState } from "modules/spending/schedule/models/schedule-store";
import { DetailedOrderList, initialState as detailedOrdersState } from "modules/main/models/detailed-order";
import { OrderMailsStore, initialState as orderMailsState, ExternalMailNumber } from "modules/order-mails/models/store";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { Constants } from "./constants";
import { urlB64ToUint8Array } from "modules/common/services/strings";
import { apiUrls } from "modules/common/services/communication/urls";
import { MainMenuStore, initialState as mainMenuState } from "./menu";
import {
  FinanceRequestsStore,
  initialState as financeRequestsState,
} from "modules/expenses/finance-requests/models/finance-requests-store";
import {
  KnowledgeBaseStore,
  initialState as knowledgeBaseState,
  KnowledgeBaseTypes,
} from "modules/technical/models/store";
import {
  ImportSpendingsStore,
  initialState as importSpendingsState,
} from "modules/expenses/import/models/import-store";
import { SpendingsList, initialState as spendingListState } from "modules/expenses/summary/models/spending-list";
import {
  OverheadSpendings,
  initialState as overheadSpendingsState,
} from "modules/expenses/overhead/models/overhead-spendings";
import { MoneyAccountStore, initialState as moneyAccountState } from "modules/main/models/money-accounts";
import { DashboardStore, initialState as dashboardState } from "modules/main/models/dashboard-store";
import { NewsStore, initialState as newsStoreState } from "modules/main/models/news-store";
import { SurveyStore, initialState as surveysState } from "modules/main/models/surveys-store";
import { CalendarEventStore, initialState as eventsState } from "modules/main/models/calendar-event-store";
import { MessageStore, initialState as messagesState } from "modules/main/models/mesages-store";
import { EmployeeWidgetStore, initialState as employeeWidgetState } from "modules/main/models/employee-store";
import { OwnSpendingsWidgetStore, initialState as ownSpendingsState } from "modules/main/models/own-spendings-store";
import { SettingsStore, initialState as settingsState } from "modules/session/settings/models/settings";
import { Changelog } from "./changelog";
import { UnitStore, initialState as unitInitialState } from "modules/spending/unit/models/unit-store";
import {
  OwnFinanceRequestsStore,
  initialState as ownFinanceRequestsState,
} from "modules/expenses/finance-requests/models/own-finance-requests";
import { emptyWorkResultStore, WorkResultStore } from "modules/orders-manage/models/order-work-result";
import {
  OrderContentTaskStore,
  initialState as orderContentTaskInititalState,
} from "modules/main/models/order-content-task-list";
import { ReportsStore, initialState as reportsInitialState } from "modules/reports/userSpending/models/reports-store";
import { OrderDictionary, initialState as orderDictionaryInite } from "../../orders-manage/models/order-dictionary";
import {
  AgentsCategoriesStore,
  initialState as agentsCategoriesInitialState,
} from "modules/dictionaries/agents-categories/models/agents-categories-store";
import {
  ExternalConfirmNumber,
  OrderConfirmStore,
  initialState as orderConfirmInite,
} from "modules/order-confirms/models/store";
import {
  ExternalQuestionNumber,
  OrderQuestionStore,
  initialState as orderQuestionInite,
} from "modules/order-questions/models/store";

let swRegistration: ServiceWorkerRegistration | null = null;
let hubConnection: signalR.HubConnection | null = null;

export const ApplicationStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      session: Session,
      settings: SettingsStore,
      tokens: TokensStore,
      startSettings: Start,
      notifications: NotificationStore,
      outsourcers: OutsourcersStore,
      suppliers: SuppliersStore,
      clients: ClientsStore,
      workTypes: WorkTypesStore,
      orderTypes: OrderTypesStore,
      orderStatuses: OrderStatusStore,
      productionStages: ProductionStagesStore,
      ipdTypes: IpdTypesStore,
      agentsCategories: AgentsCategoriesStore,
      orderIndicators: OrderIndicatorsStore,
      indicators: IndicatorsStore,
      projectPortfolios: ProjectPortfoliosStore,
      bankDetails: CompanyBankDetailsStore,
      employeePositions: EmployeePositionsStore,
      inventoryItems: MaterialValuesStore,
      overheadTypes: OverheadTypesStore,
      employee: EmployeeStore,
      departments: DepartmentsStore,
      categories: CategoriesStore,
      deprGroups: DeprGroupsStore,
      workResult: WorkResultStore,
      access: AccessStore,
      orders: OrdersStore,
      detailedOrders: DetailedOrderList,
      dashboard: DashboardStore,
      shortOrders: OrderDictionary,
      moneyAccounts: MoneyAccountStore,
      timesheet: TimesheetStore,
      reports: ReportsStore,
      unit: UnitStore,
      workload: WorkloadStore,
      schedule: ScheduleStore,
      mainMenu: MainMenuStore,
      financeRequests: FinanceRequestsStore,
      importSpendings: ImportSpendingsStore,
      spendingList: SpendingsList,
      overheadSpendings: OverheadSpendings,
      orderMails: OrderMailsStore,
      orderConfirms: OrderConfirmStore,
      orderQuestions: OrderQuestionStore,
      knowledgeBaseManuals: KnowledgeBaseStore,
      knowledgeBaseStandards: KnowledgeBaseStore,
      knowledgeBaseTemplates: KnowledgeBaseStore,
      knowledgeBaseOrders: KnowledgeBaseStore,
      knowledgeBaseOrganisations: KnowledgeBaseStore,
      newsWidget: NewsStore,
      surveysWidget: SurveyStore,
      eventsWidget: CalendarEventStore,
      messagesWidget: MessageStore,
      employeeWidget: EmployeeWidgetStore,
      ownSpendingsWidget: OwnSpendingsWidgetStore,
      orderContentTaskList: OrderContentTaskStore,
      ownFinanceRequests: OwnFinanceRequestsStore,
      // root state
      apiVersion: types.string,
      appEnvironment: types.string,
      uiVerson: types.string,
      changelog: types.array(Changelog),
      startInfoFilled: types.boolean,
    })
  )
  .views((self) => ({
    get isDevelopment() {
      return self.appEnvironment === "Development";
    },

    get isStaging() {
      return self.appEnvironment === "Staging";
    },
  }))
  .actions((self) => {
    let done = false;

    const continuation = async (pushManager: PushManager) => {
      if (!done) {
        done = true;

        if (Constants.pushPublicKey) {
          const applicationServerKey = urlB64ToUint8Array(Constants.pushPublicKey);

          let subscription = await pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey,
          });

          await self.transport.post<any>(apiUrls.application.subscribe, subscription);
        }
      }

      return done;
    };

    const catcher = (er: Error | unknown) => {
      swRegistration = null;
      done = false;
    };

    return {
      subscribe() {
        if (!swRegistration && "serviceWorker" in navigator && "PushManager" in window) {
          return navigator.serviceWorker
            .register("/sw.js")
            .then((reg) => {
              swRegistration = reg;

              if (swRegistration) {
                let serviceWorker: ServiceWorker | null = null;

                if (swRegistration.installing) {
                  serviceWorker = swRegistration.installing;
                } else if (swRegistration.waiting) {
                  serviceWorker = swRegistration.waiting;
                } else if (swRegistration.active) {
                  serviceWorker = swRegistration.active;
                }

                if (serviceWorker) {
                  if (serviceWorker.state === "activated") {
                    return continuation(swRegistration.pushManager);
                  }

                  serviceWorker.addEventListener("statechange", async (e) => {
                    const state: string = e.target ? (e.target as any).state : "";
                    if (state === "activated" && swRegistration) {
                      try {
                        await continuation(swRegistration.pushManager);
                      } catch (er) {
                        catcher(er);
                      }
                    }
                  });
                }
              }
            })
            .catch(catcher);
        }

        return Promise.resolve(false);
      },
    };
  })
  .actions((self) => ({
    registerUser: (login: string) => {
      if (login && hubConnection !== null) {
        hubConnection.invoke("ConnectMe", login);
      }

      return "";
    },

    loadChangelog: flow(function* () {
      try {
        const data: any = yield self.transport.get(apiUrls.application.changelog(self.apiVersion));
        applySnapshot(self.changelog, data);
      } catch {
        applySnapshot(self.changelog, []);
      }
    }),

    setStartupInfoState(value: boolean) {
      self.startInfoFilled = value;
    },
  }))
  .actions((self) => {
    const actions = {
      setupConnection: flow(function* (apiUrl: string) {
        hubConnection =
          hubConnection ||
          (function () {
            const hub = new signalR.HubConnectionBuilder()
              .withUrl(apiUrl + "/hub")
              .withAutomaticReconnect([500, 1000, 2000, 4000])
              .build();

            hub.onreconnected(() => {
              if (self.session.loggedIn) {
                self.registerUser(self.session.userLogin);
              }
            });

            hub.onclose(async () => {
              try {
                await hub.start();
              } catch (er) {
                setTimeout(() => actions.setupConnection(apiUrl), 5000);
              }
            });

            return hub;
          })();

        try {
          yield hubConnection.start();

          hubConnection.on("RefreshUserAccessCommand", (access: string[]) => {
            self.session.setAccess(access);
          });

          hubConnection.on("RecalculateDashboardCommand", (charts: string[]) => {
            self.dashboard.reloadCharts(charts);
          });

          hubConnection.on("SaveEmployerCommand", (data: string[]) => {
            self.session.updateUser(data);
          });

          hubConnection.on("SaveOutsourcerRequestCommand", (num: number) => {
            self.session.updateRequestAgentsCount(num);
          });

          hubConnection.on("SaveNewAgentRequest", (num: number) => {
            self.session.updateRequestAgentsCount(num);
          });

          hubConnection.on("DeleteOutsourcerRequestCommand", (num: number) => {
            self.session.updateRequestAgentsCount(num);
          });

          hubConnection.on("NotificationCommand", (data: string[]) => {
            self.notifications.addInfoNotification("warning", data[0]);
          });

          hubConnection.on("CalculationComplete", (payload: ICalculationCompletedParams) => {
            self.schedule.onCalculationCompleted(payload);
          });

          hubConnection.on("RefreshStartSettingsCommand", (payload: boolean) => {
            self.setStartupInfoState(payload);
          });

          hubConnection.on("SaveTelegramLinkCommand", (payload: string) => {
            self.session.setTgBotLink(payload);
          });

          if (self.session.loggedIn) {
            self.registerUser(self.session.userLogin);
            yield self.subscribe();
          }

          return hubConnection;
        } catch (er) {
          setTimeout(() => actions.setupConnection(apiUrl), 5000);
        }
      }),
    };

    return actions;
  })
  .actions((self) => ({
    userLoggedIn: flow(function* (metadata: AppMetadata) {
      self.apiVersion = metadata.version;
      self.orders.list.latestOrderId = metadata.latestOrderId || EMPTY_OBJECT_ID;
      self.setStartupInfoState(metadata.startInfoFilled);

      applySnapshot(Constants, metadata.constants);

      self.registerUser(metadata.userLogin);
      self.loadChangelog();

      yield self.subscribe();
    }),
  }))
  .named("ApplicationStore");

export type ApplicationStoreType = typeof ApplicationStore.Type;

export const initialState = (meta: AppConfig, version: string): typeof ApplicationStore.SnapshotType => {
  return {
    session: sessionInitialState(meta),
    settings: settingsState(),
    startSettings: startSettingsInitialState(),
    notifications: alertsInitialState(),
    outsourcers: outsourcersInitialState(),
    suppliers: suppliersInitialState(),
    clients: clientsInitialState(),
    workTypes: workTypesInitialState(),
    orderTypes: orderTypesInitialState(),
    orderStatuses: orderStatusesInitialState(),
    productionStages: productionStagesInitialState(),
    ipdTypes: ipdTypesInitialState(),
    agentsCategories: agentsCategoriesInitialState(),
    orderIndicators: orderIndicatorsInitialState(),
    indicators: indicatorsInitialState(),
    employeePositions: employeePositionsInitialState(),
    inventoryItems: materialValuesInitialState(),
    overheadTypes: overheadTypesInitialState(),
    projectPortfolios: portfoliosInitialState(),
    bankDetails: bankDetailsInitialState(),
    employee: employeeInitialState(),
    departments: departmentsInitialState(),
    categories: categoriesInitialState(),
    deprGroups: deprGroupsInitialState(),
    tokens: tokensInitilaState(),
    access: accessInitialState(),
    orders: ordersInitialState(meta),
    detailedOrders: detailedOrdersState(),
    dashboard: dashboardState(),
    moneyAccounts: moneyAccountState(),
    timesheet: timesheetInitialState(),
    unit: unitInitialState(),
    workload: workloadInitialState(),
    schedule: scheduleInitialState(),
    mainMenu: mainMenuState(),
    financeRequests: financeRequestsState(),
    importSpendings: importSpendingsState(),
    spendingList: spendingListState(),
    overheadSpendings: overheadSpendingsState(),
    messagesWidget: messagesState(),
    orderMails: orderMailsState(ExternalMailNumber()),
    orderConfirms: orderConfirmInite(ExternalConfirmNumber()),
    orderQuestions: orderQuestionInite(ExternalQuestionNumber()),
    knowledgeBaseManuals: knowledgeBaseState(KnowledgeBaseTypes.manuals),
    knowledgeBaseStandards: knowledgeBaseState(KnowledgeBaseTypes.standards),
    knowledgeBaseTemplates: knowledgeBaseState(KnowledgeBaseTypes.templates),
    knowledgeBaseOrders: knowledgeBaseState(KnowledgeBaseTypes.orders),
    knowledgeBaseOrganisations: knowledgeBaseState(KnowledgeBaseTypes.organisations),
    newsWidget: newsStoreState(),
    surveysWidget: surveysState(),
    eventsWidget: eventsState(),
    employeeWidget: employeeWidgetState(),
    ownSpendingsWidget: ownSpendingsState(),
    ownFinanceRequests: ownFinanceRequestsState(),
    orderContentTaskList: orderContentTaskInititalState(),
    apiVersion: meta.version,
    uiVerson: version,
    appEnvironment: meta.environment,
    changelog: [],
    startInfoFilled: meta.startInfoFilled,
    workResult: emptyWorkResultStore(),
    reports: reportsInitialState(),
    shortOrders: orderDictionaryInite(),
  };
};

export interface StoreWrapper {
  store: ApplicationStoreType | null;
}
