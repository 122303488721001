import * as React from "react";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { Routes as Session } from "modules/session/routes";
import { Routes as Dictionaries } from "modules/dictionaries/routes";
import { Routes as Agents } from "modules/agents/routes";
import { Routes as Orders } from "modules/orders-manage/routes";
import { Routes as Spendings } from "modules/spending/routes";
import { Routes as Expenses } from "modules/expenses/routes";
import { Routes as Technical } from "modules/technical/routes";
import { Routes as OrderMails } from "modules/order-mails/routes";
import { Routes as OrderConfirms } from "modules/order-confirms/routes";
import { Routes as OrderQuestions } from "modules/order-questions/routes";

import { ApplicationStoreType } from "./models/store";
import { observer } from "mobx-react";
import { trimEnd } from "modules/common/services/strings";
import MainAppPage from "modules/main/components/MainAppPage";
import { BotRegistration } from "modules/external/bot/components/BotRegistration";
import { Routes as Reports } from "modules/reports/routes";

export const Routes = observer(({ store }: RoutesProps) => {
  const loggedIn = store.session.loggedIn;
  const baseUrl = trimEnd("/", store.transport.defaults.baseURL || "");

  return (
    <>
      <Session
        tokens={store.tokens}
        session={store.session}
        access={store.access}
        settings={store.settings}
        baseUrl={baseUrl}
        store={store.workTypes}
      />
      <Dictionaries baseUrl={baseUrl} store={store} />
      <Agents
        baseUrl={baseUrl}
        outsourcers={store.outsourcers}
        // clients={store.clients}
        session={store.session}
        // suppliers={store.suppliers}
      />
      <Orders baseUrl={baseUrl} loggedIn={loggedIn} store={store} />
      <OrderMails loggedIn={loggedIn} store={store.orderMails} session={store.session} />
      <OrderQuestions loggedIn={loggedIn} store={store.orderQuestions} session={store.session} />

      <OrderConfirms loggedIn={loggedIn} store={store.orderConfirms} session={store.session} />
      <Spendings
        loggedIn={loggedIn}
        timesheet={store.timesheet}
        unit={store.unit}
        schedule={store.schedule}
        workload={store.workload}
        session={store.session}
        employee={store.employee}
        materials={store.inventoryItems}
      />
      <Expenses
        loggedIn={loggedIn}
        requestsStore={store.financeRequests}
        importStore={store.importSpendings}
        spendingsStore={store.spendingList}
        overheadStore={store.overheadSpendings}
        session={store.session}
      />
      <Reports
        loggedIn={loggedIn}
        session={store.session}
        reports={store.reports}
        orders={store.shortOrders}
        departments={store.departments}
        portfolios={store.projectPortfolios.dictionary}
      />
      <Technical
        loggedIn={loggedIn}
        session={store.session}
        manuals={store.knowledgeBaseManuals}
        standards={store.knowledgeBaseStandards}
        templates={store.knowledgeBaseTemplates}
        organisations={store.knowledgeBaseOrganisations}
        orders={store.knowledgeBaseOrders}
      />
      <ProtectedRoute
        exact={true}
        path={routes.main.bot()}
        loggedIn={loggedIn}
        render={(props) => <BotRegistration {...(props as any)} store={store.session} />}
      />
      <ProtectedRoute
        exact={true}
        path={routes.main.path}
        loggedIn={loggedIn}
        render={(props) => <MainAppPage {...props} root={store} baseUrl={baseUrl} />}
      />
    </>
  );
});

interface RoutesProps {
  store: ApplicationStoreType;
}
