import { Collapse } from "@blueprintjs/core";
import { Field, FieldProps } from "formik";
import { findIndex, sortBy } from "lodash";
import { observer } from "mobx-react";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { ActualPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";
import { Caret } from "modules/common/components/collapse/Caret";
import { Money } from "modules/common/components/money/Money";
import { move } from "modules/common/services/array";
import {
  OrderObjectSnapshotType,
  OrderSummaryType,
  WorkTypeLinkSnapshotType,
  fields,
  getContentUnits,
} from "modules/orders-manage/models/order";
import {
  OutsourcedOrderSpendingSnapshotType,
  OutsourcedOrderSpendingType,
} from "modules/orders-manage/models/order-spending";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable, DroppableProvided } from "react-beautiful-dnd";
import { CheckBlockWarning } from "../../validation";
import { OutsourcedSpendingObject } from "./OutsourcedObject";
import { OutsourcedSpendingRow, OutsourcerPaymentFactory } from "./OutsourcedSpendingRow";
import styles from "./OutsourcedSpendings.module.scss";
import { OUTSORCE_BLOCK_NAME, SpendingBlockSorting } from "./print-view-model";

const OBJECTS_DRAG = "outsourced-objects";
type TObject = OrderObjectSnapshotType;
type TUnit = WorkTypeLinkSnapshotType;
type TSpending = OutsourcedOrderSpendingType;

export class OutsourcedSpendings extends React.PureComponent<OutsourcedSpendingsProps> {
  private fieldProps: FieldProps | null = null;

  render() {
    const {
      agents,
      name,
      paymentFactory,
      collapsed,
      onToggleCollapse,
      toggleStatus,
      readOnly,
      details,
      newIdd,
      dirty,
    } = this.props;
    const { innerCollapser, baseUrl, newId, upload, disabled, printOrderFile } = this.props;
    return (
      <Field name={name}>
        {(fieldProps: FieldProps) => {
          this.fieldProps = fieldProps;

          const { field } = fieldProps;
          const value: TSpending[] = field.value;

          const orderTotal = value.reduce((acc, unit) => unit.actualSum + acc, 0);
          const actualTotal = value.reduce((acc, unit) => {
            return (
              acc +
              unit.actualPayments.reduce((t, p) => t + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0), 0)
            );
          }, 0);
          const units = this.getContentUnits();

          return (
            <div className={`${styles.spendings} outsourced-spendings`}>
              <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                {OUTSORCE_BLOCK_NAME}
                <Caret collapsed={collapsed} />
              </h1>

              <div className="order-total" onClick={onToggleCollapse}>
                Всего по договорам:&nbsp;
                <Money className="spendings-money" amount={orderTotal} />
              </div>

              <div
                className={`actual-total ${actualTotal < orderTotal ? "red-total" : "green-total"}`}
                onClick={onToggleCollapse}
              >
                Всего по оплатам:&nbsp;
                <Money className="spendings-money" amount={actualTotal} />
              </div>
              <div
                className={`spendings-total ${orderTotal - actualTotal !== 0 ? "red-total" : "green-total"}`}
                onClick={onToggleCollapse}
              >
                Остаток по оплатам:&nbsp;
                <Money className="spendings-money" amount={orderTotal - actualTotal} />
              </div>

              <Collapse isOpen={!collapsed} keepChildrenMounted={true} className={styles.spendingBody}>
                <div className="collapse">
                  <div className="collapse-item">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId={OBJECTS_DRAG} type={OBJECTS_DRAG} isDropDisabled={readOnly}>
                        {(provided) => (
                          <DraggableInternals
                            dirty={dirty}
                            baseUrl={baseUrl}
                            addSpending={this.addSpending}
                            agents={agents}
                            fieldProps={fieldProps}
                            innerCollapser={innerCollapser}
                            name={name}
                            paymentFactory={paymentFactory}
                            provided={provided}
                            toggleStatus={toggleStatus}
                            printOrderFile={printOrderFile}
                            units={units}
                            highlightRow={this.props.highlightRow}
                            readOnly={readOnly}
                            newId={newId}
                            upload={upload}
                            disabled={disabled}
                            details={details}
                            newIdd={newIdd}
                          />
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </Collapse>
            </div>
          );
        }}
      </Field>
    );
  }

  getContentUnits = (): TUnit[] => {
    if (!this.fieldProps) {
      return [];
    }

    return getContentUnits(this.fieldProps.form.values, true);
  };

  addSpending = async (unit: TUnit) => {
    const { fieldProps } = this;

    if (fieldProps) {
      const value: TSpending[] = fieldProps.field.value;
      const estimate = await this.props.spendingFactory.emptyOutsourcedSpending(unit, value.length + 1);

      const newValue = [...value, estimate];

      fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
      fieldProps.form.setFieldTouched(fieldProps.field.name, true);
    }
  };

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || !this.fieldProps) {
      return;
    }

    const { form, field } = this.fieldProps;

    // initial index
    const from = result.source.index;
    // new index
    const to = result.destination.index;

    // detect what exactly dragged was

    if (result.type === OBJECTS_DRAG) {
      // top level - object content

      const orderObjects = form.values[fields.objects] as TObject[];
      const content = this.getContentUnits();
      const indexes: TStringMap<number> = {};
      move(content, from, to).forEach((obj, index) => {
        indexes[obj.guid] = index + 1;
      });

      const newValue = orderObjects.map((obj) => {
        let changed = false;
        const replacement = obj.content.map((cnt) => {
          if (indexes[cnt.guid]) {
            changed = true;
            return { ...cnt, sortOrder: indexes[cnt.guid] };
          }

          return cnt;
        });
        if (changed) {
          return { ...obj, content: replacement };
        }
        return obj;
      });

      form.setFieldValue(fields.objects, newValue);
      form.setFieldTouched(fields.objects, true);
    } else {
      // second level - spending inside object

      const value = field.value as TSpending[];
      const contentGuid = result.type;
      const spendings = sortBy(
        value.filter((v) => v.contentGuid === contentGuid),
        (s: TSpending) => s.sortOrder
      );

      const indexes: TStringMap<number> = {};
      move(spendings, from, to).forEach((s, index) => {
        indexes[s.id] = index + 1;
      });

      const newValue = value.map((sp) => (indexes[sp.id] ? { ...sp, sortOrder: indexes[sp.id] } : sp));

      form.setFieldValue(field.name, newValue);
      form.setFieldTouched(field.name, true);
    }
  };
}

const DraggableInternals = observer(
  class extends React.Component<DraggableInternalsProps> {
    render() {
      const { provided, units, readOnly, addSpending, innerCollapser, agents, name, details, dirty } = this.props;
      const { fieldProps, paymentFactory, highlightRow, baseUrl, newId, disabled, newIdd } = this.props;
      const { printOrderFile, upload, toggleStatus } = this.props;
      const form = fieldProps.form;
      const value: TSpending[] = sortBy(fieldProps.field.value, SpendingBlockSorting);
      let allPayments: ActualPaymentType[] = [];
      value.forEach((sp) => {
        const unit = units.find((u) => u.guid === sp.contentGuid);
        sp.planPayments.forEach((p) => {
          const newP: ActualPaymentType = {
            ...p,
            name: unit ? p.name + " (" + unit.name + ")" : p.name,
          };
          allPayments.push(newP);
        });
      });

      return (
        <div {...provided.droppableProps} ref={provided.innerRef} className="spendings">
          {units.map((item, index) => {
            const spendings = value.filter((s) => s.contentGuid === item.guid);
            const onAdd = () => addSpending(item);
            const warning = CheckBlockWarning(spendings);

            return (
              <OutsourcedSpendingObject
                spendings={spendings}
                index={index}
                item={item}
                warning={warning}
                addSpending={onAdd}
                readOnly={disabled || readOnly}
                key={item.guid}
                collapsed={!innerCollapser.plain[item.guid]}
                onToggleCollapse={() => innerCollapser.toggle(item.guid)}
              >
                {spendings.map((spending, key) => (
                  <Draggable key={spending.id} draggableId={spending.id} index={key} isDragDisabled={readOnly}>
                    {(draggable, snapshot) => {
                      return (
                        <OutsourcedSpendingRow
                          dirty={dirty}
                          details={details}
                          disabled={disabled}
                          baseUrl={baseUrl}
                          isDragging={snapshot.isDragging}
                          draggable={draggable}
                          agents={agents}
                          paymentFactory={paymentFactory}
                          spending={spending}
                          highlight={highlightRow}
                          onChange={(field, v) => {
                            const position = findIndex(value, (s) => s.id === spending.id);
                            const newValue = [
                              ...value.slice(0, position),
                              { ...spending, [field]: v },
                              ...value.slice(position + 1),
                            ];
                            form?.setFieldValue(name, newValue);
                            form?.setFieldTouched(name, true);
                          }}
                          onRemove={() => {
                            const position = findIndex(value, (s) => s.id === spending.id);
                            const newValue = [...value.slice(0, position), ...value.slice(position + 1)];

                            form?.setFieldValue(name, newValue);
                            form?.setFieldTouched(name, true);
                          }}
                          printOrderFile={printOrderFile}
                          formDirty={form.dirty}
                          toggleStatus={toggleStatus}
                          readOnly={readOnly}
                          newId={newId}
                          upload={upload}
                          newIdd={newIdd}
                          allPayments={allPayments}
                        />
                      );
                    }}
                  </Draggable>
                ))}
              </OutsourcedSpendingObject>
            );
          })}
          {provided.placeholder}
        </div>
      );
    }
  }
);

export interface OutsourcedSpendingFactory {
  emptyOutsourcedSpending: (
    unit: WorkTypeLinkSnapshotType,
    sortOrder: number
  ) => Promise<OutsourcedOrderSpendingSnapshotType>;
}

interface OutsourcedSpendingsProps extends IdFactory, UploaderFatory {
  name: string;
  dirty: boolean;
  paymentFactory: OutsourcerPaymentFactory;
  spendingFactory: OutsourcedSpendingFactory;
  agents: OutsourcerDictionaryType;
  innerCollapser: SectionCollapserType;
  onToggleCollapse: () => void;
  collapsed: boolean;
  toggleStatus: (guid: string) => void;
  baseUrl: string;
  readOnly?: boolean;
  highlightRow?: string;
  disabled: boolean | undefined;
  printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
  details: OrderSummaryType;
  newIdd: () => Promise<string>;
}

interface DraggableInternalsProps extends IdFactory, UploaderFatory {
  name: string;
  dirty: boolean;
  readOnly?: boolean;
  fieldProps: FieldProps;
  provided: DroppableProvided;
  innerCollapser: SectionCollapserType;
  units: TUnit[];
  agents: OutsourcerDictionaryType;
  addSpending: (unit: TUnit) => void;
  toggleStatus: (guid: string) => void;
  paymentFactory: OutsourcerPaymentFactory;
  baseUrl: string;
  highlightRow?: string;
  disabled: boolean | undefined;
  printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
  details: OrderSummaryType;
  newIdd: () => Promise<string>;
}
