import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { OrderExternalParams } from "modules/orders-manage/models/order";
import { OrderQuestionSnapshotType } from "modules/orders-manage/models/order-question";
import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { OrderQuestionStoreType } from "../models/store";
import { columns } from "./columns";
import { QuestionList } from "./List";
import styles from "./Tab.module.scss";

class Questions extends React.Component<TabProps & RouteComponentProps, TabState> {
  mounted = true;
  colDefs: any;
  recordPointer = "";
  constructor(props: TabProps & RouteComponentProps) {
    super(props);

    const external = OrderExternalParams(this.props.location.hash);
    this.recordPointer = external.rowId;

    this.state = { selection: null, orderId: "" };
    this.colDefs = columns(!!props.hideProject);
  }

  async componentDidMount() {
    this.mounted = true;
    const { orderId, store } = this.props;
    if (orderId !== EMPTY_OBJECT_ID && orderId !== store.filtersOrderId) {
      await this.props.store.setOrderId(orderId, this.recordPointer, true);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      store,
      canManage,
      hideProject,
      canClientEdit,
      isActiveTab,
      canAddQuestion,
      orderId,
      currentUserId,
      orderGip,
    } = this.props;
    const { selection } = this.state;
    return (
      <div className={styles.tab}>
        {orderId !== EMPTY_OBJECT_ID ? (
          <QuestionList
            orderGip={orderGip}
            setOrder={this.recordPointer ? this.setOrderId : undefined}
            canAddQuestion={canAddQuestion}
            sorter={store.sorter}
            canManage={canManage}
            onQuestionSelected={this.questionSelected}
            current={selection}
            data={store.data}
            baseUrl={store.baseUrl}
            isActiveTab={isActiveTab}
            downloadMails={async (confirms: any) => {
              return await store.downloadQuestions(confirms, canClientEdit);
            }}
            currentUserId={currentUserId}
            employee={store.employee}
            filtersStatus={store.filtersStatus}
            filtersType={store.filtersType}
            printMails={store.printQuestions}
            query={store.query}
            remove={store.remove}
            resorted={store.resorted}
            save={async (mess: any) => {
              return await store.save(mess);
            }}
            setFiltersType={store.setFiltersType}
            setPage={store.setPage}
            setQuery={store.setQuery}
            toggleFiltersStatus={store.toggleFiltersStatus}
            setOrderId={store.setOrderId}
            upload={store.uploadFile}
            orders={store.orders}
            pager={{
              page: store.page,
              pageSize: store.pageSize,
              totalCount: store.totalCount,
            }}
            rowBuffer={store.pageSize}
            columns={this.colDefs}
            hideProject={!!hideProject}
            mailFactory={store.emptyQuestions}
            storre={store}
          />
        ) : (
          <h2>Сначала необходимо сохранить основные данные договора</h2>
        )}

        {/* <SidePanel
          selection={selection}
          onPreview={this.previewFile}
          renderer={store.renderer}
          goBack={this.goBack}
          goForward={this.goForward}
          onPrint={this.printFile}
          onDownload={this.downloadFile}
          loading={sideLoading}
          canClientEdit={canClientEdit}
        /> */}
      </div>
    );
  }

  questionSelected = (selection: OrderQuestionSnapshotType) => {
    if (!this.mounted) {
      return;
    }

    this.setState({ selection });
    if (selection) {
      // const docs =
      //   selection &&
      //   selection.questionFiles
      //     .map((file) => ({ ...file, name: `Вопрос: ${file.name}` }))
      //     .concat(selection.answerFiles.map((file) => ({ ...file, name: `Ответ: ${file.name}` })));
      // if (docs.length > 0) {
      //   this.previewFile(docs[docs.length - 1]);
      // } else {
      //   this.previewFile(null);
      // }
    }
  };

  // previewFile = (document: FileMetadataSnapshotType | null) => {
  //   const { renderer } = this.props.store;
  //   this.mounted && renderer.preview(document);
  // };

  goForward = async (from: OrderQuestionSnapshotType) => {
    const seletion = await this.props.store.moveSelection(from, 1);
    if (seletion) {
      this.questionSelected(getSnapshot(seletion));
    }
  };

  goBack = async (from: OrderQuestionSnapshotType) => {
    const seletion = await this.props.store.moveSelection(from, -1);
    if (seletion) {
      this.questionSelected(getSnapshot(seletion));
    }
  };

  // printFile = async (fileId: string) => {
  //   this.setState({ sideLoading: true });
  //   await this.props.store.printFile(fileId);
  //   this.setState({ sideLoading: false });
  // };

  // downloadFile = async (fileId: string) => {
  //   this.setState({ sideLoading: true });
  //   await this.props.store.downloadFile(fileId);
  //   this.setState({ sideLoading: false });
  // };

  setOrderId = async () => {
    this.recordPointer = "";
    let path = "";
    if (this.props.orderId) {
      path = routes.orders.details(this.props.orderId, OrderTabs.orderQuestion);
    } else {
      path = routes.orders.orderQuestions + "#c";
    }
    window.open(path, "_self");
    await this.props.store.setOrderId(this.props.orderId);
  };
}

export const QuestionsTab = withRouter(observer(Questions));

interface TabProps {
  canManage: boolean;
  store: OrderQuestionStoreType;
  hideProject?: boolean;
  canClientEdit?: boolean;
  orderId?: string;
  isActiveTab: boolean;
  canAddQuestion?: boolean;
  currentUserId: string;
  orderGip?: string | null;
}

interface TabState {
  orderId: string;
  selection: OrderQuestionSnapshotType | null;
}
