import React from "react";
import {
  OutsourcerDictionaryType,
  OutsourcerDictionaryItemSnapshotType,
} from "modules/agents/outsourcers/models/outsourcer-dictionary";
import {
  buildOptions,
  SimpleSelect,
  renderSingleOption,
  filterItemPredicate,
  DefaultSelectedOption,
  RendererProps,
} from "modules/common/services/form/select";
import { Classes, Button, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

class Base extends React.Component<OutsourcerSelectProps, OutsourcerSelectState> {
  constructor(props: OutsourcerSelectProps) {
    super(props);
    const { value, agents, includeClient } = props;
    const outsourcers = buildOptions(null, agents.confirmedOnly(value, includeClient), false);
    this.state = { outsourcers };
  }

  componentDidUpdate(prevProps: OutsourcerSelectProps) {
    const { value: previousValue } = prevProps;
    const { value, agents, includeClient } = this.props;

    if (previousValue !== value) {
      const outsourcers = buildOptions(null, agents.confirmedOnly(value, includeClient), false);
      this.setState({ outsourcers });
    }
  }

  render() {
    const { value, label, readOnly } = this.props;
    const outsourcerId = value;
    const outsourcersOptions = this.state.outsourcers;
    const outsourcer = outsourcersOptions[outsourcerId];
    const outsoursers = Object.values(outsourcersOptions);

    return (
      <SimpleSelect
        className={`full-width-select ${Classes.FILL} ${Classes.SMALL}`}
        filterable={true}
        activeItem={outsourcer}
        items={outsoursers}
        itemRenderer={renderSingleOption}
        onItemSelect={this.onChange}
        itemPredicate={filterItemPredicate}
        inputProps={{
          placeholder: label,
        }}
        popoverProps={{
          boundary: "viewport",
          usePortal: true,
          popoverClassName: "spendings-outsourserselect-dropdown",
        }}
        disabled={!outsoursers.length || readOnly}
      >
        <DefaultSelectedOption option={outsourcer} render={this.renderSelected} />
      </SimpleSelect>
    );
  }

  onChange = (item: SelectItem) => this.props.onChange(this.props.field, item.id);

  renderSelected = ({ disabled, icon, text, option }: RendererProps) => {
    const agent = option as OutsourcerDictionaryItemSnapshotType;
    const prefix = agent && agent.selfEmployed ? <Icon icon="briefcase" className="blue-icon" /> : null;

    return (
      <Button disabled={disabled} fill={true} rightIcon={icon} className="selected-option" text={text} icon={prefix} />
    );
  };
}

export const OutsourcerSelect = observer(Base);

interface OutsourcerSelectProps {
  agents: OutsourcerDictionaryType;
  value: string;
  onChange: (field: string, value: any) => void;
  field: string;
  label: string;
  includeClient?: boolean;
  readOnly?: boolean;
}

interface OutsourcerSelectState {
  outsourcers: TStringMap<SelectItem>;
}
