import { applySnapshot, types } from "mobx-state-tree";
import { SuppliersStore, initialState as agentsStoreInite } from "modules/agents/suppliers/models/suppliers-store";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";

const AgentDeal = types
  .model({
    guid: types.string,
    sum: types.string,
    date: types.string,
    comment: types.maybeNull(types.string),
    automatic: types.boolean,
    name: types.string,
    isCash: types.boolean,
    //   employerId: types.string,
  })
  .named("AgentDeal");

export const AgentPayment = types
  .model({
    guid: types.string,
    sum: types.number,
    date: types.string,
    comment: types.string,
    automatic: types.boolean,
    name: types.string,
    isCash: types.boolean,
    employerId: types.string,
  })
  .named("AgentPayment");

export type AgentDealType = typeof AgentDeal.Type;

export const Agent = types
  .model({
    guid: types.string,
    agentId: types.string,
    newReward: types.string,
    price: types.string,
    description: types.string,
    deal: types.string,
    created: types.string,
    updated: types.string,
    materials: types.array(types.string),
    deals: types.array(AgentDeal),
  })
  .named("Agent");

export const OrderAgent = types
  .model({
    id: types.string,
    orderId: types.string,
    agents: types.array(Agent),
    agentPayments: types.array(AgentPayment),
  })
  .named("OrderAgent");

export const OrderAgentsStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      agents: SuppliersStore,
      data: types.array(OrderAgent),
    })
  )
  .actions((self) => ({
    load: flow(function* (id: string) {
      try {
        const data: OrderAgentType[] = yield self.transport.get<any>(apiUrls.orders.orderAgents.list(id));
        applySnapshot(self.data, data);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("OrderAgentsStore");

export const mapAgent = (agent: AgentType): SaveAgent => ({
  guid: agent.guid,
  agentId: agent.agentId,
  newReward: agent.newReward,
  price: agent.price,
  description: agent.description,
  materials: agent.materials,
  deals: agent.deals,
  deal: agent.deal,
  created: agent.created,
  updated: agent.updated,
});

export interface SaveAgent {
  guid: string;
  agentId: string;
  newReward: string;
  price: string;
  description: string;
  deal: string;
  deals: AgentDealType[];
  created: string;
  updated: string;
  materials: string[];
}

export const agentFields = {
  newReward: nameof((a: SaveAgent) => a.newReward) as string,
  price: nameof((a: SaveAgent) => a.price) as string,
  description: nameof((a: SaveAgent) => a.description) as string,
  deal: nameof((a: SaveAgent) => a.deal) as string,
};

export type OrderAgentsStoreType = typeof OrderAgentsStore.Type;
export type OrderAgentsStoreSnapshotType = typeof OrderAgentsStore.SnapshotType;
export type OrderAgentType = typeof OrderAgent.Type;
export type OrderAgentSnapshotType = typeof OrderAgent.SnapshotType;
export type AgentType = typeof Agent.Type;
export type AgentSnapshotType = typeof Agent.SnapshotType;
export type AgentPaymentType = typeof AgentPayment.Type;
export type AgentPaymentSnapshotType = typeof AgentPayment.SnapshotType;

export const initialState = (): OrderAgentsStoreSnapshotType => ({
  data: [],
  agents: agentsStoreInite(),
});
