import * as yup from "yup";
import { questionFields as fields } from "modules/orders-manage/models/order-question";
import { texts } from "modules/common/texts";

const { object, string, boolean } = yup;

export const orderMailLabels = () => ({
  [fields.stopDate]: "Срок до",
  [fields.description]: "Вопрос",
  [fields.completed]: "Исполнено",
  [fields.employerId]: "Ответственный",
  [fields.implementerId]: "Исполнитель",
  [fields.orderId]: "Проект",
  [fields.answer]: "Ответ",
  [fields.messages]: "Новое сообщение",
});

export const OrderMailsSchema = () => {
  const labels = orderMailLabels();

  return object().shape({
    [fields.orderId]: string().label(labels[fields.orderId]).notRequired(),
    [fields.messages]: string().label(labels[fields.messages]).notRequired(),
    [fields.stopDate]: string().label(labels[fields.stopDate]).typeError(texts.messages.incorrectDate()).required(),

    [fields.implementerId]: string().label(labels[fields.implementerId]).notRequired(),
    [fields.employerId]: string().label(labels[fields.employerId]).notRequired(),

    [fields.answer]: string().label(labels[fields.answer]).notRequired(),

    [fields.completed]: boolean().label(labels[fields.completed]).notRequired(),

    [fields.description]: string().label(labels[fields.description]).notRequired(),
  });
};
