import React from "react";
import ReactDOM from "react-dom";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { Collapse } from "@blueprintjs/core";
import { Droppable, Draggable, DraggableProvided } from "react-beautiful-dnd";
import { getDragItemStyle } from "modules/common/services/drag";
import styles from "./OutsourcedSpendings.module.scss";
import { Caret } from "modules/common/components/collapse/Caret";
import portal from "../../portal";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { OutsourcedOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { Money } from "modules/common/components/money/Money";

type TUnit = WorkTypeLinkSnapshotType;
type TSpending = OutsourcedOrderSpendingType;
export class OutsourcedSpendingObject extends React.PureComponent<OutsourcedSpendingObjectProps> {
  addSpending = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.addSpending();
  };

  renderInternal = (isDragging: boolean, provided: DraggableProvided) => {
    const { item, readOnly, children, collapsed, onToggleCollapse, warning, spendings } = this.props;
    let summ: number = 0;
    let actuall: number = 0;
    spendings.forEach((items) => {
      summ += +items.actualSum;
      items.actualPayments.forEach((payment) => {
        actuall += +payment.sum - payment.correctionPayments.reduce((s, c) => s + c.sum, 0);
      });
    });

    const actual = Math.round(actuall * 100) / 100;

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`${styles.object}`}
        style={getDragItemStyle(isDragging, provided.draggableProps.style)}
      >
        <div className={`${styles.label}`} onClick={onToggleCollapse}>
          <Caret collapsed={collapsed} style={{ top: "3px" }} />
          <div className={styles.labelWrapper}>
            <div className={styles.labelContent}>
              {item.name}. {item.description}
            </div>
            {summ !== 0 && (
              <div className={styles.sumNumbersObj}>
                <div className="order-total" style={{ top: "4px", left: "190px" }}>
                  Всего по договорам:&nbsp;
                  <Money className="spendings-money" amount={summ} />
                </div>

                <div
                  className={`actual-total ${actual < summ ? "red-total" : "green-total"}`}
                  style={{ top: "4px", left: "550px" }}
                >
                  Всего по оплатам:&nbsp;
                  <Money className="spendings-money" amount={actual} />
                </div>
                <div
                  className={`spendings-total ${summ - actual !== 0 ? "red-total" : "green-total"}`}
                  style={{ top: "4px", left: "850px" }}
                >
                  Остаток по оплатам:&nbsp;
                  <Money className="spendings-money" amount={summ - actual} />
                </div>
              </div>
            )}
          </div>

          {warning && (
            <GeneralIcon
              type={"general-exclamation"}
              style={{
                color: "red",
                marginTop: "3px",
                marginLeft: "10px",
                display: "flex",
                cursor: "pointer",
              }}
              title={warning}
            />
          )}
        </div>

        <Collapse isOpen={!collapsed}>
          <Droppable droppableId={item.guid} type={item.guid} isDropDisabled={readOnly}>
            {(droppable) => (
              <div
                {...droppable.droppableProps}
                ref={droppable.innerRef}
                className={`spendings ${styles.table} ${styles.outsourcers}`}
              >
                {children}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
          {!readOnly && (
            <div>
              <PlanrButton
                type="secondary"
                icon={"general-add-user"}
                onClick={this.addSpending}
                className={"spendings-add-button"}
              >
                Добавить исполнителя
              </PlanrButton>
            </div>
          )}
        </Collapse>
      </div>
    );
  };

  render() {
    const { item, index, readOnly } = this.props;

    return (
      <Draggable key={item.guid} draggableId={item.guid} index={index} isDragDisabled={readOnly}>
        {(provided, snapshot) => {
          const child = this.renderInternal(snapshot.isDragging, provided);

          return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
        }}
      </Draggable>
    );
  }
}

interface OutsourcedSpendingObjectProps {
  index: number;
  item: TUnit;
  readOnly?: boolean;
  addSpending: () => void;
  onToggleCollapse: () => void;
  collapsed: boolean;
  warning: string;
  spendings: TSpending[];
}
