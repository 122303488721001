import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { routes } from "modules/common/routes";
import { isNotNull } from "modules/common/services/typescript";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { orderTitle } from "modules/orders-manage/models/order";
import { OrderConfirmSnapshotType, confirmFields as fields } from "modules/orders-manage/models/order-confirm";
import { StringifiedOrderLinkType } from "modules/orders-manage/models/order-link";
import { Constants } from "modules/root/models/constants";
import React from "react";
import { Link } from "react-router-dom";

export const columns = (hideProject: boolean) => (canManage: boolean) => {
  const result: (ColDef | null)[] = !hideProject
    ? [
        {
          headerName: "",
          width: 6,
          minWidth: 6,
          sortable: true,
          cellStyle: ({ data }: ICellRendererParams) => {
            let color = "#ff4949";
            if (data.type === Constants.orderConfirmTypes[Constants.orderConfirmTypes.length - 1]) {
              color = "#63ff63";
            }
            return { "background-color": color };
          },
          wrapText: true,
          autoHeight: true,
          resizable: true,
          cellClass: "flat",
          headerClass: "flat ",
        },
        canManage
          ? {
              // selection checkbox
              headerName: "",
              width: 20,
              wrapText: true,
              autoHeight: true,
              sortable: false,
              resizable: false,
              cellClass: "centered",
              checkboxSelection: true,
              // icons: {
              //   checkboxChecked: `<GeneralIcon type="general-checkbox" />`,
              //   checkboxUnchecked: `<GeneralIcon type="general-zero-checkbox" />`,
              // },
            }
          : null,

        {
          colId: "Order.Name",
          sortable: true,
          headerName: "Проект",

          field: fields.order,
          width: 160,
          headerClass: "centered",
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            const order = value as StringifiedOrderLinkType;
            if (!order) {
              return "";
            }

            return (
              <Link to={routes.orders.details(order.id, OrderTabs.orderConfirm)}>
                {orderTitle({ fullInventoryNumber: order.inventoryNumber, name: order.name })}
              </Link>
            );
          },
        },
        {
          colId: "Date",
          sortable: true,
          headerName: "Дата",
          field: fields.day,
          wrapText: true,
          autoHeight: true,
          width: 100,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const dateA = (nodeA.data as OrderConfirmSnapshotType).dateSortable;
            const dateB = (nodeB.data as OrderConfirmSnapshotType).dateSortable;
            return dateA.localeCompare(dateB);
          },
        },

        {
          colId: "Number",
          sortable: true,
          headerName: "Номер",
          wrapText: true,
          autoHeight: true,
          field: fields.number,
          width: 74,
          headerClass: "centered",
        },
        {
          colId: "Belonging",
          sortable: true,
          headerName: "Принадл к комплектам",
          cellStyle: { wordBreak: "normal", lineHeight: "unset", whiteSpace: "pre-line" },
          wrapText: true,
          autoHeight: true,
          field: fields.belonging,
          headerClass: "centered",
          width: 230,
        },
        {
          colId: "Type",
          sortable: true,
          headerName: "Основание",
          field: fields.type,
          cellStyle: { wordBreak: "normal", lineHeight: "unset" },
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 170,
        },
        {
          colId: "Description",
          sortable: true,
          headerName: "Примечания",
          autoHeight: true,
          cellStyle: { wordBreak: "normal", lineHeight: "unset" },
          field: fields.description,
          wrapText: true,
          headerClass: "centered",
          width: 150,
        },
      ]
    : [
        {
          headerName: "",
          width: 6,
          minWidth: 6,
          sortable: true,
          cellStyle: ({ data }: ICellRendererParams) => {
            let color = "#ff4949";
            if (data.type === Constants.orderConfirmTypes[Constants.orderConfirmTypes.length - 1]) {
              color = "#63ff63";
            }
            return { "background-color": color };
          },
          wrapText: true,
          autoHeight: true,
          resizable: true,
          cellClass: "flat",
          headerClass: "flat ",
        },
        canManage
          ? {
              // selection checkbox
              headerName: "",
              width: 20,
              wrapText: true,
              autoHeight: true,
              sortable: false,
              resizable: false,
              cellClass: "centered",
              checkboxSelection: true,
              icons: {
                // checkboxChecked: `<GeneralIcon type="general-checkbox" />`,
                // checkboxUnchecked: `<GeneralIcon type="general-zero-checkbox" />`,
              },
            }
          : null,

        {
          colId: "Date",
          sortable: true,
          headerName: "Дата",
          field: fields.date,
          wrapText: true,
          autoHeight: true,
          width: 95,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
        },
        {
          colId: "Number",
          sortable: true,
          headerName: "Номер",
          wrapText: true,
          autoHeight: true,
          field: fields.number,
          width: 80,
          headerClass: "centered",
        },
        {
          colId: "Belonging",
          sortable: true,
          headerName: "Принадл к комплектам",
          cellStyle: { wordBreak: "normal", lineHeight: "unset", whiteSpace: "pre-line" },
          wrapText: true,
          autoHeight: true,
          field: fields.belonging,
          headerClass: "centered",
          width: 280,
        },
        {
          colId: "Type",
          sortable: true,
          headerName: "Основание",
          field: fields.type,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 240,
        },
        {
          colId: "Description",
          sortable: true,
          headerName: "Примечания",
          autoHeight: true,
          cellStyle: { wordBreak: "normal", lineHeight: "unset" },
          field: fields.description,
          wrapText: true,
          headerClass: "centered",
          width: 200,
        },
      ];

  return result.filter(isNotNull);
};
