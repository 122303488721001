import React from "react";
import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { isNotNull } from "modules/common/services/typescript";
import { Link } from "react-router-dom";
import { routes } from "modules/common/routes";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { StringifiedOrderLinkType } from "modules/orders-manage/models/order-link";
import { orderTitle } from "modules/orders-manage/models/order";
import { fields, OrderMailSnapshotType } from "modules/orders-manage/models/order-mails";

import { Constants } from "modules/root/models/constants";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const columns = (hideProject: boolean) => (canManage: boolean) => {
  const result: (ColDef | null)[] = [
    canManage
      ? {
          // selection checkbox
          headerName: "",
          width: 20,
          sortable: false,
          resizable: false,
          cellClass: "flat centered",
          checkboxSelection: true,
          //   icons: {
          //       checkboxChecked: `<GeneralIcon type="general-checkbox" />`,
          //       checkboxUnchecked: `<GeneralIcon type="general-zero-checkbox" />`,
          //       // checkboxIndeterminate: this.checkboxIcons.inteterminate,
          //   },

          //   cellRendererFramework: ({ value }: ICellRendererParams) => {
          //       return (
          //           <PlanrButton
          //               type="neutral"
          //               icon={"general-zero-checkbox"}
          //               // style={{ width: "32px", height: "32px" }}
          //           />
          //       );
          //   },
        }
      : null,
    {
      colId: "Type",
      sortable: true,
      headerName: "Тип",
      cellClass: "flat centered",
      headerClass: "centered",
      field: fields.type,
      width: 80,

      cellRendererFramework: ({ value }: ICellRendererParams) => {
        return (
          <GeneralIcon
            type={value === Constants.incomingOrderMailType ? "general-mail-in" : "general-mail-out"}
            style={{ width: "32px", height: "32px" }}
          />
        );
      },
    },
    {
      colId: "Date",
      sortable: true,
      headerName: "Дата",
      field: fields.day,
      width: 100,
      sort: "asc",
      cellClass: "centered",
      headerClass: "centered",
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const dateA = (nodeA.data as OrderMailSnapshotType).dateSortable;
        const dateB = (nodeB.data as OrderMailSnapshotType).dateSortable;
        return dateA.localeCompare(dateB);
      },
    },
    {
      colId: "Number",
      sortable: true,
      headerName: "Номер",
      field: fields.number,
      width: 90,
      cellClass: "flat righted",
      headerClass: "centered",
    },
    {
      colId: "Name",
      sortable: true,
      headerName: "Тема",
      field: fields.name,
      headerClass: "centered",
      flex: 1,
    },
    {
      colId: "Counterpart",
      sortable: true,
      headerName: "Кому/От кого",
      field: fields.counterpart,
      headerClass: "centered",
      cellClass: "flat",
      width: 120,
    },
    !hideProject
      ? {
          colId: "Order.Name",
          sortable: true,
          headerName: "Проект",
          field: fields.order,
          width: 160,
          headerClass: "centered",
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            const order = value as StringifiedOrderLinkType;
            if (!order) {
              return "";
            }

            return (
              <Link to={routes.orders.details(order.id, OrderTabs.mails)}>
                {orderTitle({ fullInventoryNumber: order.inventoryNumber, name: order.name })}
              </Link>
            );
          },
        }
      : null,
    {
      colId: "Assignee.Name",
      sortable: true,
      headerName: "Исполнитель",
      field: fields.assignee,
      headerClass: "centered",
      width: 120,
      valueGetter: ({ data }) => {
        const row: OrderMailSnapshotType = data;
        return row.assignee ? row.assignee.name : "";
      },
    },
    {
      sortable: false,
      headerName: "Статус",
      cellClass: "flat centered",
      headerClass: "centered",
      field: fields.status,
      width: 100,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        const row: OrderMailSnapshotType = data;

        if (row.accepted) {
          return (
            <span className={"color-green"} style={{ height: "20px", padding: "0px 7px" }}>
              {Constants.acceptedOrderMailStatus}
            </span>
          );
        }

        if (row.completed) {
          return (
            <span className={"color-orange"} style={{ height: "20px", padding: "0px 7px" }}>
              {Constants.completedOrderMailStatus}
            </span>
          );
        }

        if (row.expired) {
          return (
            <span className={"color-red"} style={{ height: "20px", padding: "0px 7px" }}>
              {Constants.expiredOrderMailStatus}
            </span>
          );
        }

        return <span></span>;
      },
    },
  ];

  return result.filter(isNotNull);
};
