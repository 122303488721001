import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
  DefaultSelectedOption,
  SimpleSelect,
  filterItemPredicate,
  renderSingleOption,
} from "modules/common/services/form/select";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { SessionType } from "modules/session/auth/models/session";
import React from "react";
import { ReportsStoreType } from "../models/reports-store";
import styles from "./ReportsPage.module.scss";
import { DepartmentsStoreType } from "modules/spending/departments/models/departments-store";

const startDateLocal = "ReportUnitStartDate";
const stopDateLocal = "ReportUnitStopDate";

const groupByLocal = "ReportUnitGroupBy";

class Page extends React.Component<ReportsPageProps, ReportsPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      startDate: null,
      stopDate: null,
      orderIds: [],
      departmentIds: [],
      groupBy: "order",
      loading: false,
      selectedOrder: null,
      selectedDepartment: null,
    };
  }

  async componentDidMount() {
    await this.props.orders.load();
    await this.props.departments.list.load();
    let local = localStorage.getItem(startDateLocal);
    try {
      if (local) {
        this.setState({ startDate: new Date(JSON.parse(local)) });
      }
    } catch (e) {
      /*Ignore*/
    }
    let local2 = localStorage.getItem(stopDateLocal);
    try {
      if (local2) {
        this.setState({ stopDate: new Date(JSON.parse(local2)) });
      }
    } catch (e) {
      /*Ignore*/
    }
    let local3 = localStorage.getItem(groupByLocal);
    try {
      if (local3) {
        this.setState({ groupBy: JSON.parse(local3) });
      }
    } catch (e) {
      /*Ignore*/
    }
  }

  render() {
    const { store, orders, departments } = this.props;
    const { startDate, stopDate, orderIds, groupBy, loading, selectedOrder, departmentIds, selectedDepartment } =
      this.state;
    const orderItems = orders.orders.map((o) => {
      return { id: o.id, label: o.inventoryNumber + " " + o.name };
    });
    const departmentItems = departments.list.departments.map((o) => {
      return { id: o.name, label: o.name };
    });
    return (
      <div className={styles.page}>
        <LocalLoader active={loading} />
        <div className={styles.reports}>
          <div className={styles.reportUnitSpending}>
            <div className={styles.reportLabel}>Трудозатраты</div>
            <div className={styles.dates}>
              <div className={styles.date}>
                <div className={styles.dateLabel}>От</div>
                <div className={styles.datePicker}>
                  <DatePicker
                    clasName="planr-default-input"
                    value={startDate}
                    onChange={(date) => {
                      localStorage.setItem(startDateLocal, JSON.stringify(date));
                      this.setState({ startDate: date });
                    }}
                  />
                </div>
              </div>
              <div className={styles.date}>
                <div className={styles.dateLabel}>До</div>
                <div className={styles.datePicker}>
                  <DatePicker
                    clasName="planr-default-input"
                    value={stopDate}
                    onChange={(date) => {
                      localStorage.setItem(stopDateLocal, JSON.stringify(date));
                      this.setState({ stopDate: date });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.orders}>
              <div>Проекты</div>
              <SimpleSelect
                className={`full-width-select ${Classes.FILL}`}
                activeItem={selectedOrder}
                items={orderItems}
                itemRenderer={renderSingleOption}
                onItemSelect={(item: SelectItem) => {
                  if (!orderIds.find((r) => r === item.id)) {
                    this.setState({ orderIds: [...this.state.orderIds, item.id] });
                  }
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
                }}
                inputProps={{
                  placeholder: "Проект",
                }}
                filterable
                itemPredicate={filterItemPredicate}
              >
                <DefaultSelectedOption option={selectedOrder} />
              </SimpleSelect>
            </div>

            {orderIds.length > 0 && (
              <div className={styles.selectedOrders}>
                {orderIds.map((o) => {
                  const order = orders.orders.find((i) => i.id === o);

                  if (order) {
                    return (
                      <div className={styles.orderRow} key={order.id}>
                        <div className={styles.orderRowLabel}>{order.inventoryNumber + " " + order.name}</div>

                        <PlanrButton
                          onClick={() => {
                            let ids = [...orderIds];
                            const index = ids.findIndex((id) => id === order.id);
                            if (index > -1) {
                              ids.splice(index, 1);
                              this.setState({ orderIds: ids });
                            }
                          }}
                          icon="general-trash"
                          type="neutral"
                          size="small"
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}

            <div className={styles.orders}>
              <div>Отделы</div>
              <SimpleSelect
                className={`full-width-select ${Classes.FILL}`}
                activeItem={selectedDepartment}
                items={departmentItems}
                itemRenderer={renderSingleOption}
                onItemSelect={(item: SelectItem) => {
                  if (!departmentIds.find((r) => r === item.label)) {
                    this.setState({ departmentIds: [...this.state.departmentIds, item.label] });
                  }
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
                }}
                inputProps={{
                  placeholder: "Проект",
                }}
                filterable
                itemPredicate={filterItemPredicate}
              >
                <DefaultSelectedOption option={selectedDepartment} />
              </SimpleSelect>
            </div>

            {departmentIds.length > 0 && (
              <div className={styles.selectedOrders}>
                {departmentIds.map((o) => {
                  const order = departments.list.departments.find((i) => i.name === o);

                  if (order) {
                    return (
                      <div className={styles.orderRow} key={order.id}>
                        <div className={styles.orderRowLabel}>{order.name}</div>

                        <PlanrButton
                          onClick={() => {
                            let ids = [...departmentIds];
                            const index = ids.findIndex((id) => id === order.name);
                            if (index > -1) {
                              ids.splice(index, 1);
                              this.setState({ departmentIds: ids });
                            }
                          }}
                          icon="general-trash"
                          type="neutral"
                          size="small"
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}

            <div className={styles.groupBy}>
              <span>Группировка</span>
              <div className={styles.groupByRow}>
                <PlanrButton
                  type={groupBy === "order" ? "primary" : "secondary"}
                  onClick={() => {
                    localStorage.setItem(groupByLocal, JSON.stringify("order"));
                    this.setState({ groupBy: "order" });
                  }}
                  size="small"
                  style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                >
                  Проект - Отдел
                </PlanrButton>
                <PlanrButton
                  type={groupBy === "department" ? "primary" : "secondary"}
                  onClick={() => {
                    localStorage.setItem(groupByLocal, JSON.stringify("department"));
                    this.setState({ groupBy: "department" });
                  }}
                  size="small"
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    marginLeft: "0px",
                  }}
                >
                  Отдел - Проект
                </PlanrButton>
                <PlanrButton
                  type={groupBy === "workType" ? "primary" : "secondary"}
                  onClick={() => {
                    localStorage.setItem(groupByLocal, JSON.stringify("workType"));
                    this.setState({ groupBy: "workType" });
                  }}
                  size="small"
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    marginLeft: "0px",
                  }}
                >
                  Проект - Вид работ
                </PlanrButton>
              </div>
            </div>
            {startDate && (
              <div>
                <PlanrButton
                  type="greenish"
                  size="small"
                  icon="general-export"
                  onClick={async () => {
                    this.setState({ loading: true });
                    await store.report(startDate, stopDate, orderIds, departmentIds, groupBy);
                    this.setState({ loading: false });
                  }}
                >
                  Сформировать
                </PlanrButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const ReportsPage = observer(Page);

interface ReportsPageProps {
  store: ReportsStoreType;
  orders: OrderDictionaryType;
  session: SessionType;
  departments: DepartmentsStoreType;
}

interface ReportsPageState {
  startDate: Date | null;
  stopDate: Date | null;
  orderIds: string[];
  departmentIds: string[];
  loading: boolean;
  selectedOrder: SelectItem | null;
  selectedDepartment: SelectItem | null;
  groupBy: "order" | "department" | "workType";
}
