import React, { useCallback } from "react";

import { texts } from "modules/common/texts";

import styles from "./Actions.module.scss";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OrderQuestionSnapshotType } from "modules/orders-manage/models/order-question";

export const Actions: React.FC<ActionsProps> = (props) => {
  const { questions, onRemove, onAdd, canAddQuestion } = props;

  const removeConfirm = useCallback(() => questions.length && onRemove(questions), [onRemove, questions]);
  let canRemove = true;

  questions.forEach((question) => {
    if (!question.canRemoveQuestion) canRemove = false;
  });

  return (
    <div className={styles.actions}>
      {/* <DictioantyAddButton onClick={onAdd} label="" /> */}

      <PlanrButton
        type="secondary"
        icon={"general-plus-big"}
        round={true}
        onClick={onAdd}
        style={{ marginRight: "8px" }}
        disabled={!canAddQuestion}
      />

      <div className={styles.removeBtn}>
        <PlanrButton
          type="graybtn"
          icon={"general-trash"}
          size="small"
          round={true}
          onClick={removeConfirm}
          title={texts.remove}
          disabled={questions.length && canRemove ? false : true}
        />
      </div>
    </div>
  );
};

interface ActionsProps {
  questions: OrderQuestionSnapshotType[];
  onRemove: (mails: OrderQuestionSnapshotType[]) => void;
  onAdd: () => void;
  canAddQuestion?: boolean;
}
