import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { DATE_FORMAT } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { eat, isNotNull } from "modules/common/services/typescript";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { orderTitle } from "modules/orders-manage/models/order";
import { StringifiedOrderLinkType } from "modules/orders-manage/models/order-link";
import {
  MessageType,
  OrderQuestionSnapshotType,
  tableFields as fields,
} from "modules/orders-manage/models/order-question";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./List.module.scss";
import msgStyles from "./Form.module.scss";

import { FileLink } from "modules/common/components/files/FileLink";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const columns = (hideProject: boolean) => (canManage: boolean, baseUrl: string) => {
  const result: (ColDef | null)[] = !hideProject
    ? [
        {
          headerName: "",
          width: 6,
          minWidth: 6,
          sortable: true,
          cellStyle: ({ data }: ICellRendererParams) => {
            let color = "#49a7ff";
            let nowDate = new Date();
            nowDate.setHours(0, 0, 0, 0);
            const date = moment(data.stopDate, DATE_FORMAT).toDate();
            if (data.stopDate && date < nowDate && !data.completed) {
              color = "#e31818";
            }
            if (!data.completed && data.stopDate && date >= nowDate && (data.answer || data.answerFiles.length > 0)) {
              color = "#fceb00";
            }

            if (data.completed) {
              color = "#63ff63";
            }

            return { "background-color": color, "min-height": "50px" };
          },
          wrapText: true,
          autoHeight: true,
          resizable: true,
          cellClass: "flat",
          headerClass: "flat ",
        },

        canManage
          ? {
              // selection checkbox
              headerName: "",
              width: 20,
              wrapText: true,
              autoHeight: true,
              sortable: false,
              resizable: false,
              cellClass: "centered",
              checkboxSelection: true,
              // icons: {
              //   checkboxChecked: `<GeneralIcon type="general-checkbox" />`,
              //   checkboxUnchecked: `<GeneralIcon type="general-zero-checkbox" />`,
              // },
            }
          : null,
        {
          colId: "Number",
          sortable: true,
          headerName: "№",
          field: fields.number,
          wrapText: true,
          autoHeight: true,
          width: 85,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
        },
        {
          colId: "Order.Name",
          sortable: true,
          headerName: "Проект",

          wrapText: true,
          autoHeight: true,
          field: fields.order,
          width: 160,
          headerClass: "centered",
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            const order = value as StringifiedOrderLinkType;
            if (!order) {
              return "";
            }

            return (
              <Link to={routes.orders.details(order.id, OrderTabs.orderQuestion)}>
                {orderTitle({ fullInventoryNumber: order.inventoryNumber, name: order.name })}
              </Link>
            );
          },
        },
        {
          colId: "Date",
          sortable: true,
          headerName: "Дата",
          field: fields.day,
          wrapText: true,
          autoHeight: true,
          width: 100,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const dateA = (nodeA.data as OrderQuestionSnapshotType).dateSortable;
            const dateB = (nodeB.data as OrderQuestionSnapshotType).dateSortable;
            return dateA.localeCompare(dateB);
          },
        },
        {
          colId: "Author",
          sortable: false,
          headerName: "Сотрудники",
          field: fields.author,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 250,
          cellStyle: () => {
            return { "min-height": `120px` };
          },
          cellRendererFramework: ({ data }: ICellRendererParams) => {
            const style: React.CSSProperties = { display: "flex", height: "30px", alignItems: "center" };
            const styleLabel: React.CSSProperties = { display: "flex", width: "110px" };
            return (
              <div>
                <div style={style}>
                  <div style={styleLabel}>Инициатор: </div>
                  <div>
                    {data.author ? <span>{data.author.name}</span> : <span className={styles.noDataCell}>Нет</span>}
                  </div>
                </div>
                <div style={style}>
                  <div style={styleLabel}>Ответственный: </div>
                  <div>
                    {data.employer ? <span>{data.employer.name}</span> : <span className={styles.noDataCell}>Нет</span>}
                  </div>
                </div>
                <div style={style}>
                  <div style={styleLabel}>Исполнитель: </div>
                  <div>
                    {data.implementer ? (
                      <span>{data.implementer.name}</span>
                    ) : (
                      <span className={styles.noDataCell}>Нет</span>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          colId: "StopDate",
          sortable: true,
          headerName: "Срок",
          field: fields.stopDays,
          wrapText: true,
          autoHeight: true,
          width: 100,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const dateA = (nodeA.data as OrderQuestionSnapshotType).stopDateSortable;
            const dateB = (nodeB.data as OrderQuestionSnapshotType).stopDateSortable;
            return dateA.localeCompare(dateB);
          },
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.oldStopDates.length > 0) {
              height = 35 * data.oldStopDates.length;
            }
            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ data }: ICellRendererParams) => {
            // const arr =
            //   data && data.stopDays.length > 1
            //     ? data.stopDays.sort((a: any, b: any) => new Date(b).valueOf() - new Date(a).valueOf())
            //     : [];
            return data && data.oldStopDates.length > 0 ? (
              <div>
                {data.oldStopDates.map((day: string, index: number) => {
                  return (
                    <div key={index} style={{ textDecoration: "line-through" }}>
                      {day}
                    </div>
                  );
                })}
                <div>{data.stopDay}</div>
              </div>
            ) : (
              data.stopDay
            );
          },
        },
        {
          colId: "Description",
          sortable: true,
          headerName: "Вопрос",
          autoHeight: true,
          cellStyle: { wordBreak: "normal", lineHeight: "unset" },
          field: fields.description,
          wrapText: true,
          headerClass: "centered",
          width: 300,
        },
        {
          colId: "QuestionFiles",
          sortable: true,
          headerName: "Файл",
          field: fields.questionFiles,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.questionFiles.length > 1) {
              height = 45 * data.questionFiles.length;
            }
            return { "min-height": `${height}px` };
          },
          width: 65,
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div>
                {value.map((file: any, index: number) => {
                  return (
                    <FileLink
                      baseUrl={baseUrl}
                      file={{ fileId: file.id, fileName: file.name }}
                      key={index}
                      readOnly={true}
                      littleIcon
                      onRemove={eat}
                    />
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Answers",
          sortable: true,
          headerName: "Ответ",
          field: fields.answer,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 300,
        },
        {
          colId: "AnswerFiles",
          sortable: true,
          headerName: "Файл",
          field: fields.answerFiles,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 65,
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.answerFiles.length > 1) {
              height = 45 * data.answerFiles.length;
            }
            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div>
                {value.map((file: any, index: number) => {
                  return (
                    <FileLink
                      baseUrl={baseUrl}
                      file={{ fileId: file.id, fileName: file.name }}
                      key={index}
                      readOnly={true}
                      littleIcon
                      onRemove={eat}
                    />
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Messages",
          sortable: true,
          headerName: "Сообщения",
          field: fields.messages,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 300,
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            const lineHeight = 14; // Высота строки
            if (data.messages.length > 0) {
              data.messages.forEach((msg: any) => {
                const textHeight = calculateTextHeight(msg.description, 270, lineHeight);
                height += 20;
                height += textHeight;
              });
            }

            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div className={msgStyles.msgs}>
                {value.map((msg: MessageType, index: number) => {
                  return (
                    <div key={index} className={msgStyles.message}>
                      <div className={msgStyles.messageHeader}>
                        <div>{msg.employer ? msg.employer.name : "Вы"}</div>
                        <div> {`${msg.day} ${msg.hours}`}</div>
                      </div>
                      <div className={msgStyles.messageText}>{msg.description}</div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Completed",
          sortable: true,
          headerName: "Исполн",
          field: fields.completed,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 100,

          cellRendererFramework: (params: ICellRendererParams) => {
            const { data } = params;

            const getContext = () => params.context as TableContext;
            return (
              <div className={styles.visibleHover}>
                <GeneralIcon
                  size={20}
                  style={{ cursor: "pointer" }}
                  type={data.completed ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={
                    data.canChangeCompleted
                      ? (e: React.MouseEvent) => {
                          e.stopPropagation();

                          getContext().setCompleted(data.id);
                        }
                      : eat
                  }
                />
              </div>
            );
          },
        },
      ]
    : [
        {
          headerName: "",
          width: 6,
          minWidth: 6,
          sortable: true,
          cellStyle: ({ data }: ICellRendererParams) => {
            let color = "#49a7ff";
            let nowDate = new Date();
            nowDate.setHours(0, 0, 0, 0);
            const date = moment(data.stopDate, DATE_FORMAT).toDate();
            if (data.stopDate && date < nowDate && !data.completed) {
              color = "#e31818";
            }
            if (!data.completed && data.stopDate && date >= nowDate && (data.answer || data.answerFiles.length > 0)) {
              color = "#fceb00";
            }

            if (data.completed) {
              color = "#63ff63";
            }

            return { "background-color": color, "min-height": "50px" };
          },
          wrapText: true,
          autoHeight: true,
          resizable: true,
          cellClass: "flat",
          headerClass: "flat ",
        },

        canManage
          ? {
              // selection checkbox
              headerName: "",
              width: 20,
              wrapText: true,
              autoHeight: true,
              sortable: false,
              resizable: false,
              cellClass: "centered",
              checkboxSelection: true,
              // icons: {
              //   checkboxChecked: `<GeneralIcon type="general-checkbox" />`,
              //   checkboxUnchecked: `<GeneralIcon type="general-zero-checkbox" />`,
              // },
            }
          : null,
        {
          colId: "Number",
          sortable: true,
          headerName: "№",
          field: fields.number,
          wrapText: true,
          autoHeight: true,
          width: 85,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
        },

        {
          colId: "Date",
          sortable: true,
          headerName: "Дата",
          field: fields.day,
          wrapText: true,
          autoHeight: true,
          width: 100,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const dateA = (nodeA.data as OrderQuestionSnapshotType).dateSortable;
            const dateB = (nodeB.data as OrderQuestionSnapshotType).dateSortable;
            return dateA.localeCompare(dateB);
          },
        },
        {
          colId: "Author",
          sortable: false,
          headerName: "Сотрудники",
          field: fields.author,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 250,
          cellStyle: () => {
            return { "min-height": `120px` };
          },
          cellRendererFramework: ({ data }: ICellRendererParams) => {
            const style: React.CSSProperties = { display: "flex", height: "30px", alignItems: "center" };
            const styleLabel: React.CSSProperties = { display: "flex", width: "110px" };
            return (
              <div>
                <div style={style}>
                  <div style={styleLabel}>Инициатор: </div>
                  <div>
                    {data.author ? <span>{data.author.name}</span> : <span className={styles.noDataCell}>Нет</span>}
                  </div>
                </div>
                <div style={style}>
                  <div style={styleLabel}>Ответственный: </div>
                  <div>
                    {data.employer ? <span>{data.employer.name}</span> : <span className={styles.noDataCell}>Нет</span>}
                  </div>
                </div>
                <div style={style}>
                  <div style={styleLabel}>Исполнитель: </div>
                  <div>
                    {data.implementer ? (
                      <span>{data.implementer.name}</span>
                    ) : (
                      <span className={styles.noDataCell}>Нет</span>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          colId: "StopDate",
          sortable: true,
          headerName: "Срок",
          field: fields.stopDays,
          wrapText: true,
          autoHeight: true,
          width: 100,
          sort: "asc",
          cellClass: "centered",
          headerClass: "centered",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const dateA = (nodeA.data as OrderQuestionSnapshotType).stopDateSortable;
            const dateB = (nodeB.data as OrderQuestionSnapshotType).stopDateSortable;
            return dateA.localeCompare(dateB);
          },
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.oldStopDates.length > 0) {
              height = 35 * data.oldStopDates.length;
            }
            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ data }: ICellRendererParams) => {
            // const arr =
            //   data && data.stopDays.length > 1
            //     ? data.stopDays.sort((a: any, b: any) => new Date(b).valueOf() - new Date(a).valueOf())
            //     : [];
            return data && data.oldStopDates.length > 0 ? (
              <div>
                {data.oldStopDates.map((day: string, index: number) => {
                  return (
                    <div key={index} style={{ textDecoration: "line-through" }}>
                      {day}
                    </div>
                  );
                })}
                <div>{data.stopDay}</div>
              </div>
            ) : (
              data.stopDay
            );
          },
        },
        {
          colId: "Description",
          sortable: true,
          headerName: "Вопрос",
          autoHeight: true,
          cellStyle: { wordBreak: "normal", lineHeight: "unset" },
          field: fields.description,
          wrapText: true,
          headerClass: "centered",
          width: 300,
        },
        {
          colId: "QuestionFiles",
          sortable: true,
          headerName: "Файл",
          field: fields.questionFiles,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.questionFiles.length > 1) {
              height = 45 * data.questionFiles.length;
            }
            return { "min-height": `${height}px` };
          },
          width: 65,
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div>
                {value.map((file: any, index: number) => {
                  return (
                    <FileLink
                      baseUrl={baseUrl}
                      file={{ fileId: file.id, fileName: file.name }}
                      key={index}
                      readOnly={true}
                      littleIcon
                      onRemove={eat}
                    />
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Answers",
          sortable: true,
          headerName: "Ответ",
          field: fields.answer,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 300,
        },
        {
          colId: "AnswerFiles",
          sortable: true,
          headerName: "Файл",
          field: fields.answerFiles,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 65,
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            if (data.answerFiles.length > 1) {
              height = 45 * data.answerFiles.length;
            }
            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div>
                {value.map((file: any, index: number) => {
                  return (
                    <FileLink
                      baseUrl={baseUrl}
                      file={{ fileId: file.id, fileName: file.name }}
                      key={index}
                      readOnly={true}
                      littleIcon
                      onRemove={eat}
                    />
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Messages",
          sortable: true,
          headerName: "Сообщения",
          field: fields.messages,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          width: 300,
          cellStyle: ({ data }: ICellRendererParams) => {
            let height = 0;
            const lineHeight = 14; // Высота строки
            if (data.messages.length > 0) {
              data.messages.forEach((msg: any) => {
                const textHeight = calculateTextHeight(msg.description, 270, lineHeight);
                height += 20;
                height += textHeight;
              });
            }

            return { "min-height": `${height}px` };
          },
          cellRendererFramework: ({ value }: ICellRendererParams) => {
            return value && value.length ? (
              <div className={msgStyles.msgs}>
                {value.map((msg: MessageType, index: number) => {
                  return (
                    <div key={index} className={msgStyles.message}>
                      <div className={msgStyles.messageHeader}>
                        <div>{msg.employer ? msg.employer.name : "Вы"}</div>
                        <div> {`${msg.day} ${msg.hours}`}</div>
                      </div>
                      <div className={msgStyles.messageText}>{msg.description}</div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          },
        },
        {
          colId: "Completed",
          sortable: true,
          headerName: "Исполн",
          field: fields.completed,
          wrapText: true,
          autoHeight: true,
          headerClass: "centered",
          cellClass: "centered",
          width: 100,

          cellRendererFramework: (params: ICellRendererParams) => {
            const { data } = params;

            const getContext = () => params.context as TableContext;
            return (
              <div className={styles.visibleHover}>
                <GeneralIcon
                  size={20}
                  style={{ cursor: "pointer" }}
                  type={data.completed ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={
                    data.canChangeCompleted
                      ? (e: React.MouseEvent) => {
                          e.stopPropagation();

                          getContext().setCompleted(data.id);
                        }
                      : eat
                  }
                />
              </div>
            );
          },
        },
      ];

  return result.filter(isNotNull);
};

function calculateTextHeight(text: string, width: number, lineHeight: number): number {
  // Создаем временный элемент
  const tempElement = document.createElement("div");

  // Устанавливаем стили для временного элемента
  tempElement.style.position = "absolute"; // Чтобы элемент не влиял на layout
  tempElement.style.visibility = "hidden"; // Скрываем элемент
  tempElement.style.width = `${width}px`;
  tempElement.style.lineHeight = `${lineHeight}px`;
  tempElement.style.whiteSpace = "normal"; // Разрешаем перенос строк
  tempElement.style.wordWrap = "break-word"; // Перенос слов, если они не помещаются

  // Вставляем текст во временный элемент
  tempElement.textContent = text;

  // Добавляем временный элемент в DOM
  document.body.appendChild(tempElement);

  // Получаем высоту элемента
  const height = tempElement.clientHeight;

  // Удаляем временный элемент из DOM
  document.body.removeChild(tempElement);

  return height;
}

export interface TableContext {
  setCompleted: (id: string) => Promise<void>;
}
