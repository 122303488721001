import React from "react";
import { OrderQuestionStoreType } from "../models/store";
import { Page } from "modules/common/components/page/Page";
import { QuestionsTab } from "./Tab";
import styles from "./OrderQuestionsPage.module.scss";
import { Tabs, Tab } from "@blueprintjs/core";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { SessionType } from "modules/session/auth/models/session";
import { observer } from "mobx-react";
import func from "modules/orders-manage/functionalities";
import { can } from "modules/session/auth/access";

const menu = menuItems();

export const OrderQuestionsPage = observer(
  class extends React.PureComponent<OrderQuestionsPageProps> {
    // constructor(props: OrderQuestionsPageProps) {
    //   super(props);

    //   props.store.load();
    // }

    render() {
      const { store, session } = this.props;

      return (
        <Page className={styles.page}>
          <Tabs
            className={`${styles.tabs} planr-tabs-navigation`}
            id="OrderQuestionsPage"
            selectedTabId="1"
            renderActiveTabPanelOnly={true}
          >
            <Tab
              id="1"
              title={menu.orderQuestions.label}
              panel={
                <QuestionsTab
                  store={store}
                  currentUserId={session.userId}
                  canManage={can(func.ORDERS_QUESTION_EDIT, session.access)}
                  isActiveTab
                  canAddQuestion={can(func.FLOW_ORDERS_CHART, session.access)}
                />
              }
            />
          </Tabs>
        </Page>
      );
    }
  }
);

interface OrderQuestionsPageProps {
  store: OrderQuestionStoreType;
  session: SessionType;
}
