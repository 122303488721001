import React from "react";
import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { SessionType } from "modules/session/auth/models/session";
import { OrderQuestionStoreType } from "./models/store";
import { OrderQuestionsPage } from "./components/OrderQuestionsPage";

const path = routes.orders.orderQuestions;

export const Routes = ({ store, session, loggedIn }: RoutesProps) => {
  return (
    <>
      <ProtectedRoute
        path={path}
        loggedIn={loggedIn}
        render={(props) => <OrderQuestionsPage {...props} store={store} session={session} />}
      />
    </>
  );
};

interface RoutesProps extends LoggedInProps {
  store: OrderQuestionStoreType;
  session: SessionType;
}
