import { saveAs } from "file-saver";
import { types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";

const NAME = "ReportsStore";

export const ReportsStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      loading: types.boolean,
      start: types.string,
      end: types.string,
      orderIds: types.array(types.string),
    })
  )
  .actions((self) => ({
    reportOrderYear: flow(function* (year: string | null, portfolio: string | null) {
      self.loading = true;

      try {
        if (year === null) {
          self.notify.error("Трудозатраты: необходимо указать период !");
          return false;
        }
        let file: FileDescription;

        file = yield self.transport.post(apiUrls.orders.reportOrderYear, {
          year,
          portfolioId: portfolio,
        });

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
      } catch (er) {
        self.notify.error(er);
        return false;
      } finally {
        self.loading = false;
      }
    }),
    report: flow(function* (
      start: Date | null,
      end: Date | null,
      orderIds: string[],
      departmentNames: string[],
      groupBy: "order" | "department" | "workType" = "order"
    ) {
      self.loading = true;

      try {
        if (start === null) {
          self.notify.error("Трудозатраты: необходимо указать период !");
          return false;
        }
        let file: FileDescription;

        file = yield self.transport.post(apiUrls.timesheet.unit.report, {
          start: formatDate(start),
          end: formatDate(end),
          orderIds,
          departmentNames,
          groupBy,
        });

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
      } catch (er) {
        self.notify.error(er);
        return false;
      } finally {
        self.loading = false;
      }
    }),
  }))
  .named(NAME);

export type ReportsStoreType = typeof ReportsStore.Type;
export type ReportsStoreSnapshotType = typeof ReportsStore.SnapshotType;

export const initialState = (): ReportsStoreSnapshotType => {
  return {
    loading: false,
    start: "",
    end: "",
    orderIds: [],
  };
};
