import React from "react";

import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { Classes, Popover } from "@blueprintjs/core";
import { FileLink } from "modules/common/components/files/FileLink";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { DATE_FORMAT } from "modules/common/constants";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { eat } from "modules/common/services/typescript";
import { OrderContentTaskListItemType, fields } from "modules/main/models/order-content-task-list";
import moment from "moment";
import { RenderOrderStatus } from "../table/columns";
import styles from "./TaskList.module.scss";
import { texts } from "modules/common/texts";
export const columns = (tableName: string, baseUrl: string, setCopied: () => void) => {
  const columnSize = getColumnSize(tableName);

  const result: ColDef[] = [
    // RowNumberColumn(false, ""),
    {
      headerName: "Инв",
      field: fields.inventoryNumber,
      width: columnSize(fields.inventoryNumber, 60),
      sortable: true,
      resizable: false,
    },
    {
      headerName: "Договор",
      field: fields.orderName,
      width: columnSize(fields.orderName, 150),
      sortable: true,
      resizable: false,
    },
    {
      headerName: "Статус",
      field: fields.orderStatus,
      width: columnSize(fields.orderStatus, 100),
      sortable: true,
      resizable: false,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        // const status = stat.asMap[row.statusId];

        return RenderOrderStatus(data.orderStatus, data.orderStatusColor);
      },
    },
    {
      headerName: "Вид работ",
      field: fields.unitName,
      width: columnSize(fields.unitName, 100),
      sortable: true,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data.unitDescription && data.type === "task") {
          return (
            <Popover
              position="left"
              usePortal={true}
              interactionKind="hover"
              content={<div className={styles.unitDescription}>{`${data.unitName} - ${data.unitDescription}`}</div>}
            >
              <div className={`${styles.cutRow} ${Classes.TOOLTIP_INDICATOR}`} style={{ maxWidth: "150px" }}>
                {data.unitName}
              </div>
            </Popover>
          );
        }
        if (data.type === "confirm") {
          return <div style={{ maxWidth: "230px" }}>Подтвердите</div>;
        }
        if (data.type === "orderRemove") {
          return <div style={{ maxWidth: "230px" }}>Запрос на</div>;
        }
        if (data.type === "agent") {
          return (
            <Popover
              position="left"
              usePortal={true}
              interactionKind="hover"
              content={<div className={styles.unitDescription}>{`${data.unitName}`}</div>}
            >
              <div className={`${styles.cutRow} ${Classes.TOOLTIP_INDICATOR}`} style={{ maxWidth: "150px" }}>
                {data.unitName}
              </div>
            </Popover>
          );
        }
        return null;
      },
    },

    {
      headerName: "Задание",
      field: fields.description,
      width: columnSize(fields.description, 150),
      sortable: true,
      resizable: false,
      valueGetter: (params: any) => {
        return params.data.task?.description;
      },
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data.task && data.task.description) {
          return (
            <Popover
              position="left"
              usePortal={true}
              interactionKind="hover"
              content={<div className={styles.unitDescription}>{data.task.description}</div>}
            >
              <div className={`${styles.cutRow} ${Classes.TOOLTIP_INDICATOR}`} style={{ maxWidth: "230px" }}>
                {data.task.description}
              </div>
            </Popover>
          );
        }
        if (data.type === "confirm") {
          return <div style={{ maxWidth: "230px" }}>загрузку последней</div>;
        }
        if (data.type === "orderRemove") {
          return <div style={{ maxWidth: "230px" }}>удаление проекта</div>;
        }
        return null;
      },
    },
    {
      headerName: "Ответств",
      field: fields.mentorName,
      width: columnSize(fields.mentorName, 120),
      sortable: true,
      resizable: false,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data && data.type !== "confirm" && data.type !== "orderRemove") {
          return <span>{data.mentorName}</span>;
        }
        if (data.type === "confirm") {
          return <span>версии проекта</span>;
        }
        if (data.type === "orderRemove") {
          return null;
        }
        return null;
      },
      valueGetter: (params: any) => {
        if (params.data.type !== "confirm") {
          return params.data.mentorName;
        }
      },
    },
    {
      headerName: "Выдал",
      field: fields.task,
      width: columnSize(fields.task, 120),
      sortable: true,
      resizable: false,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data.task && data.task.author) {
          return <span>{data.task.author}</span>;
        }
        if (data.type === "confirm" || data.type === "orderRemove") {
          return <span>{data.mentorName}</span>;
        }
        return null;
      },
      valueGetter: (params: any) => {
        if (params.data.type === "confirm" || params.data.type === "orderRemove") {
          return params.data.mentorName;
        } else {
          return params.data.task?.author;
        }
      },
    },
    {
      headerName: "Дата от",
      field: fields.task,
      width: columnSize(fields.task, 85),
      sortable: true,
      resizable: false,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data.task && data.task.date) {
          return <span>{data.task.date}</span>;
        }
        if (data.type === "confirm") {
          return <span>{data.dateStart}</span>;
        }
        if (data.type === "orderRemove") {
          return <span>{data.dateStart}</span>;
        }
        if (data.type === "agent") {
          return <span>{data.dateStart}</span>;
        }
        return null;
      },
      valueGetter: (params: any) => {
        if (params.data.type === "confirm" || params.data.type === "agent" || params.data.type === "orderRemove") {
          return params.data.dateStart;
        } else {
          return params.data.task?.date;
        }
      },
    },
    {
      headerName: "Дата до",
      field: fields.task,
      width: columnSize(fields.task, 95),
      sortable: true,
      resizable: false,
      valueGetter: (params: any) => {
        return params.data.task?.endDate;
      },
      cellRendererFramework: (params: ICellRendererParams) => {
        const { data } = params;
        let nowDate = new Date();
        nowDate.setHours(0, 0, 0, 0);
        if (data.task && data.task.endDate) {
          return (
            <span
              style={{
                color: moment(data.task.endDate, DATE_FORMAT).toDate() < nowDate ? "#e31818" : "",
              }}
            >
              {data.task.endDate}
            </span>
          );
        }
        if (data.type === "confirm" || data.type === "agent") {
          return null;
        }
        if (data.type === "orderRemove") {
          const getContext = () => params.context as TableContext;
          return (
            <PlanrButton
              type="danger"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();

                getContext().setShowRemove(data);
              }}
              style={{ height: "25px", width: "80px", fontSize: "10px", padding: "0px 7px" }}
            >
              <div>
                <div>{texts.remove}</div>
                <div>проект</div>
              </div>
            </PlanrButton>
          );
        }
        return <span className={styles.noDataCell}>Нет</span>;
      },
    },
    {
      headerName: "Исполнитель",
      field: fields.task,
      width: columnSize(fields.task, 120),
      sortable: true,
      resizable: false,
      valueGetter: (params: any) => {
        return params.data.task?.implementer ? params.data.task.implementer?.name : "";
      },
      cellRendererFramework: (params: ICellRendererParams) => {
        const { data } = params;
        if (data.type === "confirm" || data.type === "agent") {
          return null;
        }
        if (data.type === "orderRemove") {
          const getContext = () => params.context as TableContext;
          return (
            <PlanrButton
              type="blueish"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();

                getContext().setCancel(data);
              }}
              style={{ height: "25px", width: "80px", fontSize: "10px", padding: "0px 7px" }}
            >
              <div>
                <div>Отклонить</div>
                <div>запрос</div>
              </div>
            </PlanrButton>
          );
        }
        return (
          <div className={styles.implementer}>
            {data.task?.implementer ? data.task?.implementer.name : <span className={styles.noDataCell}>Нет</span>}
          </div>
        );
      },
    },
    {
      headerName: "Файлы",
      field: fields.task,
      width: columnSize(fields.task, 80),
      sortable: true,
      resizable: false,
      valueGetter: (params: any) => {
        return params.data.task?.taskFile.length;
      },
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        return (
          <div style={{ display: "flex" }}>
            {data.task?.taskFile &&
              data.task.taskFile.map((file: any) =>
                file.path ? (
                  <Popover
                    key={file.id}
                    position="left"
                    usePortal={true}
                    interactionKind="hover"
                    content={
                      <div className={styles.pathWrapper}>
                        <div>
                          <div>Путь к файлу</div>
                          <div className={styles.pathText}>{file.path}</div>
                        </div>

                        <PlanrButton
                          type="secondary"
                          icon="general-copy"
                          title="Копировать"
                          size="small"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(file.path ? file.path : "");
                            } catch (e) {
                            } finally {
                              setCopied();
                            }
                          }}
                        />
                      </div>
                    }
                  >
                    <FileLink<any>
                      key={file.id}
                      baseUrl={baseUrl}
                      file={{
                        fileId: file.id,
                        fileName: file.name,
                      }}
                      littleIcon
                      readOnly={true}
                      onRemove={eat}
                      style={{
                        marginRight: "8px",
                        borderBottom: "1px dotted #00273d",
                      }}
                    />
                  </Popover>
                ) : (
                  <FileLink<any>
                    key={file.id}
                    baseUrl={baseUrl}
                    file={{
                      fileId: file.id,
                      fileName: file.name,
                    }}
                    littleIcon
                    readOnly={true}
                    onRemove={eat}
                    style={{ marginRight: "8px" }}
                  />
                )
              )}
          </div>
        );
      },
    },
    {
      headerName: "Статус",
      field: fields.task,
      width: columnSize(fields.task, 90),
      sortable: true,
      resizable: false,
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        if (data.task && data.type !== "orderQuestion") {
          return (
            <div className={styles.statuses}>
              {data.task.accepted && !data.task.completed ? (
                <span className={styles.acceptedDataCell}>Принял</span>
              ) : data.task.completed ? (
                <span className={styles.withDataCell}>Выполнил</span>
              ) : (
                <span className={styles.noDataCell}>Нет</span>
              )}
            </div>
          );
        }

        if (data.type === "orderQuestion") {
          return (
            <div className={styles.statuses}>
              {data.task.completed && <span className={styles.withDataCell}>Исполнено</span>}
              {data.task.accepted && <span className={styles.acceptedDataCell}>Неисполнено</span>}
              {!data.task.completed && !data.task.accepted && <span className={styles.noDataCell}>Просрочено</span>}
            </div>
          );
        }

        return null;
      },
      valueGetter: (params: any) => {
        let res = 0;
        if (params.data.task?.accepted && !params.data.task?.completed) {
          res = 1;
        }
        if (params.data.task?.completed) {
          res = 2;
        }
        return res;
      },
    },
  ];

  return result;
};

export interface TableContext {
  setShowRemove: (orderTask: OrderContentTaskListItemType) => Promise<void>;
  setCancel: (orderTask: OrderContentTaskListItemType) => Promise<void>;
}
